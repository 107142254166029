import { createSelector, createFeatureSelector } from '@ngrx/store';
import { RecommendationState, selectAll, selectEntities } from './recommendation.reducer';

export const getRecomendationState = createFeatureSelector<RecommendationState>('recommendation');

export const getAll = createSelector(
  getRecomendationState,
  selectAll
);

export const getAllAfterLoad = createSelector(
  getRecomendationState,
  getAll,
  (state, recs) => (state.firstLoad ? recs : null)
);

export const getEntities = createSelector(
  getRecomendationState,
  selectEntities
);

export const getEntity = (id: number) =>
  createSelector(
    getEntities,
    entities => entities[id]
  );

export const getCarouselPageIndex = createSelector(
  getRecomendationState,
  (state => state.carouselPageIndex)
  );

export const getSortOption = createSelector(
  getRecomendationState,
  (state => state.sortOption)
  );

export const getListFilters = createSelector(
  getRecomendationState,
  (state =>  state.listFilters)
  );
