import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { map, switchMap, filter, first, withLatestFrom, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

import { RefFacadeService, UserFacadeService } from 'sbx-ui-core';
import {
  Recommendation,
  ACTION_LIKE,
  ACTION_BIN,
  ACTION_1,
  ACTION_2,
  DO_NOTHING_RECOMMENDATION_TYPE,
  REDUCE_PRICE_CP_RECOMMENDATION_TYPE,
} from '../../../../../../../../../api/abstracts/insights';

import { RecommendationFacadeService } from '../../services/recommendation-facade.service';
import { getCurrencySymbol } from '@angular/common';
import { RecommendationWithForecast } from '../../state';
import { PreviousUrlService } from '../../services/previous-url.service';

export interface RecommendationAction extends RecommendationWithForecast {
  netSalesToMD: number;
  mdStockUnits: number;
  mdDiscountRequired: number;
  mdPrice: number;
  terminalStock: number;
  disposalCost: number;
  cashMarginOnRemainingSales: number;
  netMargin: number;
}

export interface RecommendationInfo extends RecommendationAction {
  currentCover: number;
  projectedFPSalesPerWeek: number;
  cashMarginIncrease: number;
  cashMarginIncreasePercent: number;
  terminalStockDecrease: number;
  terminalStockDecreasePercent: number;
  disposalCostDecrease: number;
  disposalCostDecreasePercent: number;
  nextMDEvent: string;
  netMargin: number;
  netMarginPercent: number;
  currentPriceMin?: number;
  currentPriceMax?: number;
  costPriceMin?: number;
  costPriceMax?: number;
  avgMarginMax?: number;
  avgMarginMin?: number;
  ageMax?: number;
  ageMin?: number;
  targetStockMax?: number;
  targetStockMin?: number;
  maxActionPrice?: number;
  minActionPrice?: number;
}

@Component({
  selector: 'app-recommendation-detail',
  templateUrl: './recommendation-detail.component.html',
  styleUrls: ['./recommendation-detail.component.scss'],
})
export class RecommendationDetailComponent implements OnInit {
  recs$: Observable<RecommendationAction[]>;
  info$: Observable<RecommendationInfo>;
  userCurrency$: Observable<string>;
  takenRecAction$: Observable<RecommendationAction>;
  relatedRecRegions$: Observable<RecommendationWithForecast[]>;
  allRegionSelected$ = new BehaviorSubject(false);

  recToClass = {
    pricedown: 'reduce-price',
    pricedownCP: 'reduce-price',
    pricedownP: 'reduce-price',
    'increase price': 'increase-price',
    'reduce price': 'reduce-price',
    'remove discount': 'remove-discount',
  };

  recToString = {
    pricedown: '- $ reduce price',
    pricedownCP: '- $ reduce price',
    pricedownP: '- $ reduce price',
    'reduce price': '- $ reduce price',
    'remove discount': 'remove discount',
    'increase price': '+ $ increase price',
  };
  summaryRecs$: Observable<
    {
      currentPrice: number;
      recommendedPrice: number;
      cashMarginIncrease: number;
      terminalStockDecrease: number;
      totalInventory: number;
      lastWeekCover: number;
      region: string;
    }[]
  >;
  graphView = false;
  rowData;
  previousPageUrl$: BehaviorSubject<string>;
  constructor(
    private recommendationFacadeService: RecommendationFacadeService,
    private route: ActivatedRoute,
    private userFacadeService: UserFacadeService,
    private refFacadeService: RefFacadeService,
    private router: Router,
    private previousUrlService: PreviousUrlService
  ) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd  && !!this.router.getCurrentNavigation().previousNavigation),
    first(),
    map(() => this.router.getCurrentNavigation().previousNavigation.finalUrl.toString()))
    .subscribe(previousUrl => {
      if (previousUrl === '/' || previousUrl === '/all' ) {
        this.previousUrlService.previousUrl$.next(previousUrl);
      }
    });
  }

  ngOnInit() {
    const id$ = this.route.params.pipe(map(({ id }) => id));
    const regions$ = this.refFacadeService.getRegion().pipe(filter(regions => !!regions));
    const relatedRecs$ = id$.pipe(switchMap(id => this.recommendationFacadeService.getAllRelated(+id)));
    this.relatedRecRegions$ = relatedRecs$.pipe(
      map(({ related }) =>
        _(related)
          .filter(({ recommended }) => recommended === 1)
          .uniqBy('region')
          .value()
      )
    );
    this.recs$ = combineLatest([
      combineLatest([relatedRecs$, this.allRegionSelected$]).pipe(
        map(([{ related, rec }, allRegionSelected]) => {
          if (allRegionSelected) {
            return related;
          }

          return related.filter(({ region }) => region === rec.region);
        })
      ),
      regions$,
    ]).pipe(
      map(([recs, regions]) =>
        _(recs)
          .map(rec => {
            const { event2_date, region } = rec;
            const { vat_rate } = _.find(regions, ({ id }) => id === region);
            const action1Forecasts = rec.forecast.filter(({ cal_date }) => +moment(cal_date) < +moment(event2_date));

            const { price: action1 } = _.first(action1Forecasts);
            const action2Forecast = _(rec.forecast)
              .filter(({ cal_date }) => +moment(cal_date) >= +moment(event2_date))
              .first();

            const { price: action2 } = action2Forecast;
            const terminalStock = _(rec.forecast).maxBy(({ cal_date }) => +moment(cal_date)).fcst_end_stock_u;
            const revenue = _(rec.forecast)
              .map(({ price, fcst_net_sales_u }) => price * fcst_net_sales_u)
              .sum();
            const cost = _(rec.forecast)
              .map(({ fcst_net_sales_u }) => rec.unit_cost * fcst_net_sales_u)
              .sum();
            const skuDisposalCost = rec.disposal_cost || 4;
            const cashMarginOnRemainingSales = revenue / (1 + vat_rate) - cost;
            const disposalCost = terminalStock * skuDisposalCost;
            return {
              ...rec,
              netSalesToMD: action1Forecasts.reduce((acc, { fcst_net_sales_u }) => acc + fcst_net_sales_u, 0),
              mdStockUnits: _(action1Forecasts).maxBy(({ cal_date }) => +moment(cal_date)).fcst_end_stock_u,
              mdDiscountRequired: (action1 - action2) / action1,
              mdPrice: action2,
              terminalStock,
              disposalCost,
              cashMarginOnRemainingSales,
              netMargin: cashMarginOnRemainingSales - disposalCost,
              vat_rate
            };
          })
          .orderBy(['rank'], ['asc'])
          .value()
      )
    );

    const recsByRegion$ = this.recs$.pipe(
      map(recs => {
        const x = _(recs)
          .groupBy('region')
          .mapValues(regionRecs => {
            const recommendedAction = _.find(regionRecs, ({ recommended }) => recommended) as RecommendationAction;
            const noAction = _.find(
              regionRecs,
              ({ recommendation_type }) => recommendation_type === REDUCE_PRICE_CP_RECOMMENDATION_TYPE
            ) as RecommendationAction;

            return { recommendedAction, noAction };
          })
          .value();

        return x;
      })
    );

    this.summaryRecs$ = recsByRegion$.pipe(
      map(recsByRegion => {
        return _.map(recsByRegion, ({ recommendedAction, noAction }, region) => ({
          currentPrice: recommendedAction.current_price,
          recommendedPrice: recommendedAction.action_price,
          cashMarginIncrease: recommendedAction.netMargin - noAction.netMargin,
          terminalStockDecrease: recommendedAction.terminalStock - noAction.terminalStock,
          totalInventory: recommendedAction.total_inventory_u,
          lastWeekCover: recommendedAction.total_inventory_u / recommendedAction.lw_sales_u,
          region,
        }));
      })
    );

    this.info$ = recsByRegion$.pipe(
      filter(rec => !!rec),
      map(recsByRegion => {
        const regions = _.keys(recsByRegion);
        if (regions.length === 1) {
          const regionKey = _.first(regions);
          const { recommendedAction: rec, noAction } = recsByRegion[regionKey];

          return {
            ...rec,
            currentCover: rec.total_inventory_u / rec.lw_sales_u,
            projectedFPSalesPerWeek: 0, // getProjectedFPSalesPerWeek(moment(rec.target_date), rec.fcst_net_sales_u),
            target_date: moment(rec.target_date).format('YYYY-MM-DD'),
            cashMarginIncrease: rec.cashMarginOnRemainingSales - noAction.cashMarginOnRemainingSales,
            cashMarginIncreasePercent:
              (rec.cashMarginOnRemainingSales - noAction.cashMarginOnRemainingSales) / rec.cashMarginOnRemainingSales,
            terminalStockDecrease: rec.terminalStock - noAction.terminalStock,
            terminalStockDecreasePercent: (rec.terminalStock - noAction.terminalStock) / rec.terminalStock,
            disposalCostDecrease: rec.disposalCost - noAction.disposalCost,
            disposalCostDecreasePercent: (rec.disposalCost - noAction.disposalCost) / rec.disposalCost,
            nextMDEvent: rec.event2_date,
            netMargin: rec.cashMarginOnRemainingSales - rec.disposalCost - (noAction.cashMarginOnRemainingSales - noAction.disposalCost),
            netMarginPercent:
              (rec.cashMarginOnRemainingSales - rec.disposalCost - (noAction.cashMarginOnRemainingSales - noAction.disposalCost)) /
              (rec.cashMarginOnRemainingSales - rec.disposalCost),
          };
        } else {
          const regionKey = _.first(regions);
          const { recommendedAction: rec } = recsByRegion[regionKey];

          const allRecs = _(recsByRegion)
            .map(({ recommendedAction, noAction }) => [recommendedAction, noAction])
            .flatten()
            .value();

          const info = { ...rec } as RecommendationInfo;
          // get range of current price
          const maxCurrentPrice = _.maxBy(allRecs, 'current_price');
          const minCurrentPrice = _.minBy(allRecs, 'current_price');
          if (maxCurrentPrice !== minCurrentPrice) {
            info.currentPriceMax = maxCurrentPrice.current_price;
            info.currentPriceMin = minCurrentPrice.current_price;
          }
          const maxCostPrice = _.maxBy(allRecs, 'unit_cost');
          const minCostPrice = _.minBy(allRecs, 'unit_cost');
          if (maxCostPrice !== minCostPrice) {
            info.costPriceMax = maxCostPrice.unit_cost;
            info.costPriceMin = minCostPrice.unit_cost;
          }
          const maxAvgMargin = _.maxBy(allRecs, 'avg_margin');
          const minAvgMargin = _.minBy(allRecs, 'avg_margin');
          if (maxAvgMargin !== minAvgMargin) {
            info.avgMarginMax = maxAvgMargin.avg_margin;
            info.avgMarginMin = minAvgMargin.avg_margin;
          }

          const maxAge = _.maxBy(allRecs, 'age');
          const minAge = _.minBy(allRecs, 'age');
          if (maxCostPrice !== minCostPrice) {
            info.ageMax = maxAge.age;
            info.ageMin = minAge.age;
          }

          const maxActionPrice = _.maxBy(allRecs, 'action_price');
          const minActionPrice = _.minBy(allRecs, 'action_price');
          if (maxActionPrice !== minActionPrice) {
            info.maxActionPrice = maxActionPrice.action_price;
            info.minActionPrice = minActionPrice.action_price;
          }

          const uniqRecsByRegion = _(allRecs).uniqBy('region');

          info.stock_on_hand = uniqRecsByRegion.sumBy('stock_on_hand');
          info.lw_sales_u = uniqRecsByRegion.sumBy('lw_sales_u');
          info.avg_4_sales_u = uniqRecsByRegion.sumBy('avg_4_sales_u');
          info.total_inventory_u = uniqRecsByRegion.sumBy('total_inventory_u');
          info.target_stock = uniqRecsByRegion.sumBy('target_stock');

          const recActionTerminalStockSum = _.sumBy(_.map(recsByRegion, ({ recommendedAction }) => recommendedAction), 'terminalStock');
          const noActionTerminalStockSum = _.sumBy(_.map(recsByRegion, ({ noAction }) => noAction), 'terminalStock');
          info.terminalStockDecrease = recActionTerminalStockSum - noActionTerminalStockSum;

          info.netMargin = _.sumBy(_.map(recsByRegion, ({ recommendedAction }) => recommendedAction), 'cash_margin_increase');

          info.target_date = undefined;

          return info;
        }
      })
    );

    this.takenRecAction$ = this.recs$.pipe(
      map(recs =>
        _(recs)
          .filter(({ take }) => take)
          .first()
      )
    );

    this.userCurrency$ = this.userFacadeService.getUserCurrency();
    this.userCurrency$
      .pipe(
        filter(currency => !!currency),
        first()
      )
      .subscribe(currency => {
        const userCurrencySymbol = getCurrencySymbol(currency, 'narrow');
        _.keys(this.recToString).forEach(key => {
          this.recToString[key] = this.recToString[key].replace('$', userCurrencySymbol);
        });
      });
    this.previousPageUrl$ = this.previousUrlService.getPreviousUrl();
  }

  compareAllRegions() {
    this.allRegionSelected$.next(true);
  }

  selectRegion() {
    this.allRegionSelected$.next(false);
    this.graphView = false;
  }

  onClickLike(event: MouseEvent, recommendation: Recommendation) {
    if (recommendation.bin) {
      return;
    }
    event.stopPropagation();
    const { id, like } = recommendation;
    if (like) {
      const { id: actionID } = _.find(recommendation.actions, ({ action }) => action === ACTION_LIKE);

      this.recommendationFacadeService.unlikeRecommendation(actionID, id);
    } else {
      this.recommendationFacadeService.likeRecommendation(id);
    }
  }

  undoBin(info: Recommendation) {
    const { id } = info;
    const binRec = _.find(info.actions, ({ action }) => action === ACTION_BIN);
    if (binRec) {
      const { id: actionID } = binRec;

      this.recommendationFacadeService.unbinRecommendation(actionID, id);
    }
  }

  bin(info: Recommendation) {
    const { id } = info;
    this.recommendationFacadeService.binRecommendation(id);
  }

  onShowGraphView(data) {
    this.graphView = data ? true : false;
    if (data) {
      this.rowData = data;
    }
  }
}
