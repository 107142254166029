import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RecommendationFacadeService } from '..';
import { switchMap, catchError, tap, filter, first } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProductHistoryGuard implements CanActivate {
  constructor(private recommendationFacadeService: RecommendationFacadeService) {}

  getFromStoreOrAPI(selectedId: string): Observable<any> {
    let loadingStarted = false;
    return this.recommendationFacadeService.getAllRelated(+selectedId).pipe(
      filter(({ related: recommendations }) => recommendations && recommendations.length > 0),
      tap(({ related: recommendations }) => {
        if (!loadingStarted) {
          const { region } = _.find(recommendations, ({ id }) => id === +selectedId);
          const regionRecsIds = recommendations.filter(d => !d.productHistory && d.region === region).map((({ id }) => id));
          if (regionRecsIds.length > 0) {
           this.recommendationFacadeService.loadProductHistory(regionRecsIds);
          }
        }
        loadingStarted = true;
      }),
      filter(({ related: recommendations }) => recommendations.filter(({ forecast }) => !forecast || forecast.length === 0).length === 0),
      first()
    );
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const id = next.paramMap.get('id');
    return this.getFromStoreOrAPI(id).pipe(
      switchMap(() => of(true)),
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
  }

}
