import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RecommendationFacadeService } from '..';
import { switchMap, catchError, tap, filter, first, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ForecastGuard implements CanActivate {
  constructor(private recommendationFacadeService: RecommendationFacadeService) {}

  getFromStoreOrAPI(id: string): Observable<any> {
    return this.recommendationFacadeService.getAllRelated(+id).pipe(
      filter(({ related: recommendations }) => recommendations && recommendations.length > 0),
      first(),
      tap(({ related: recommendations }) => {
        recommendations.filter(d => !d.forecast).forEach(({ id: recId }) => this.recommendationFacadeService.loadForecast(+recId));
      }),
      switchMap(() => this.recommendationFacadeService.getAllRelated(+id)),
      filter(({ related: recommendations }) => recommendations.filter(({ forecast }) => !forecast || forecast.length === 0).length === 0),
      first()
    );
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const id = next.paramMap.get('id');
    return this.getFromStoreOrAPI(id).pipe(
      switchMap(() => of(true)),
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
  }
}
