import { Component, OnInit } from '@angular/core';
import { RefFacadeService } from 'sbx-ui-core';

@Component({
  selector: 'app-disposal-cost-cell-editor',
  templateUrl: './disposal-cost-cell-editor.component.html',
  styleUrls: ['./disposal-cost-cell-editor.component.scss']
})
export class DisposalCostCellEditorComponent implements OnInit {
  params: any;
  value;
  private cancelBeforeStart = false;

  constructor(private refFacadeService: RefFacadeService) { }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.cancelBeforeStart = (params.charPress && '1234567890.'.indexOf(params.charPress) < 0);
  }

  ngOnInit() {
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    const isNonNumber = /[^$,.\d]/.test(this.value);
    return isNonNumber;
  }

  onChange(event) {
    if (this.isCancelAfterEnd()) {
      this.value = this.params.value;
    } else {
      const params = {
        'level': 'h2',
        'id': this.params.data.h2_id,
        'changes': {
          'disposal_cost': (this.value !== '') ? this.value : null
        }
      };
      this.refFacadeService.updateMerchHierarchy(params);
    }
  }

}
