import { Component, OnInit, Input } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

import { filter, first } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

import { UserFacadeService } from 'sbx-ui-core';

import { Recommendation } from '../../../../../../../../../api/abstracts/insights';
import { RecommendationFacadeService } from '../../services/recommendation-facade.service';
import { ACTION_TAKE, ACTION_LIKE, MARGIN_SORT, VOLUME_SORT } from '../../../../../../../../../api/abstracts/insights';


@Component({
  selector: 'app-recommendation-grid',
  templateUrl: './recommendation-grid.component.html',
  styleUrls: ['./recommendation-grid.component.scss'],
})
export class RecommendationGridComponent implements OnInit {
  @Input()
  recommendations: Recommendation[];

  @Input()
  sortOptionChanged$: BehaviorSubject<string>;

  recToClass = {
    pricedown: 'reduce-price',
    pricedownCP: 'reduce-price',
    pricedownP: 'reduce-price',
    'increase price': 'increase-price',
    'reduce price': 'reduce-price',
    'remove discount': 'remove-discount',
  };

  recToString = {
    pricedown: '- $ reduce price',
    pricedownCP: '- $ reduce price',
    pricedownP: '- $ reduce price',
    'reduce price': '- $ reduce price',
    'remove discount': 'remove discount',
    'increase price': '+ $ increase price',
  };

  userCurrency$: Observable<string>;
  sortOption = MARGIN_SORT;
  constructor(private userFacadeService: UserFacadeService, private recommendationFacadeService: RecommendationFacadeService) {}

  ngOnInit() {
    this.userCurrency$ = this.userFacadeService.getUserCurrency();
    this.userCurrency$
      .pipe(
        filter(currency => !!currency),
        first()
      )
      .subscribe(currency => {
        const userCurrencySymbol = getCurrencySymbol(currency, 'narrow');
        _.keys(this.recToString).forEach(key => {
          this.recToString[key] = this.recToString[key].replace('$', userCurrencySymbol);
        });
      });
  }

  onClickTake(event: MouseEvent, recommendation: Recommendation) {
    event.stopPropagation();

    const { id, take } = recommendation;
    if (take) {
      const { id: actionID } = _.find(recommendation.actions, ({ action }) => action === ACTION_TAKE);

      this.recommendationFacadeService.untakeRecommendation(actionID, id);
    } else {
      this.recommendationFacadeService.takeRecommendation(id);
    }
  }

  onClickBin(event: MouseEvent, recommendation: Recommendation) {
    event.stopPropagation();

    const { id } = recommendation;
    this.recommendationFacadeService.binRecommendation(id);
  }

  onClickLike(event: MouseEvent, recommendation: Recommendation) {
    event.stopPropagation();
    const { id, like } = recommendation;
    if (like) {
      const { id: actionID } = _.find(recommendation.actions, ({ action }) => action === ACTION_LIKE);

      this.recommendationFacadeService.unlikeRecommendation(actionID, id);
    } else {
      this.recommendationFacadeService.likeRecommendation(id);
    }
  }
}
