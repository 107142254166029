import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { Recommendation, Forecast, ProductHistory } from '../../../../../../../../api/abstracts/insights/recommendation.model';
import {
  LoadAllSuccess,
  LikeSuccess,
  UnlikeSuccess,
  UntakeSuccess,
  UnbinSuccess,
  TakeSuccess,
  BinSuccess,
  LoadForecastSuccess,
  LoadForecast,
  UpdateCarouselPage,
  UpdateSortOption,
  UpdateListFilter,
  LoadProductHistorySuccess,
} from './recommendation.actions';
import {  MARGIN_SORT } from '../../../../../../../../api/abstracts/insights/constants';

export interface RecommendationWithForecast extends Recommendation {
  forecast?: Forecast[];
  productHistory?: ProductHistory[];
}

export interface FilterTypes {
  skuId: string[];
  recommendation: { id: string, value: string }[];
  department: { h2_desc: string; h2_id: string }[];
  season: string[];
  collection: string[];
  region: string[];
  status: { id: string, value: string }[];
  sliderMin: string;
  sliderMax: string;
}

export interface RecommendationState extends EntityState<RecommendationWithForecast> {
  carouselPageIndex: number;
  sortOption: string;
  listFilters: FilterTypes;
  firstLoad: boolean;
}

export const recommendationAdapter: EntityAdapter<RecommendationWithForecast> = createEntityAdapter<RecommendationWithForecast>();

export const initialState: RecommendationState = recommendationAdapter.getInitialState({
  carouselPageIndex: 0,
  sortOption: MARGIN_SORT,
  listFilters: {
    skuId: [],
    recommendation: [],
    department: [],
    season: [],
    collection: [],
    region: [],
    status: [],
    sliderMin: null,
    sliderMax: null,
  },
  firstLoad: false,
});

const recommendationReducer = createReducer(
  initialState,
  on(LoadAllSuccess, (state, { entities }) => ({ ...recommendationAdapter.addAll(entities, state), firstLoad: true })),
  on(LoadForecast, (state, { id }) => ({ ...recommendationAdapter.updateOne({ changes: { forecast: [] }, id }, state) })),
  on(LoadForecastSuccess, (state, { entities, id }) => ({
    ...recommendationAdapter.updateOne({ id, changes: { forecast: entities } }, state),
  })),
  on(LoadProductHistorySuccess, (state, { entities, regionRecsIds }) => ({
    ...recommendationAdapter.updateMany(regionRecsIds.map((Id) => ({ id: Id, changes: { productHistory: entities }})), state),
  })),
  on(LikeSuccess, TakeSuccess, BinSuccess, (state, { action }) => {
    const newState = {
      ...state,
      entities: {
        ...state.entities,
        [action.recommendation_id]: {
          ...state.entities[action.recommendation_id],
          ...{ [action.action]: true },
        },
      },
    };

    if (newState.entities[action.recommendation_id].actions) {
      newState.entities[action.recommendation_id].actions.push(action);
    } else {
      newState.entities[action.recommendation_id].actions = [action];
    }

    return newState;
  }),
  on(UnlikeSuccess, UntakeSuccess, UnbinSuccess, (state: RecommendationState, { type, actionID, id: recommendationID }) => {
    let action: string;
    if (type === UnlikeSuccess.type) {
      action = 'like';
    } else if (type === UntakeSuccess.type) {
      action = 'take';
    } else if (type === UnbinSuccess.type) {
      action = 'bin';
    }
    const newState = {
      ...state,
      entities: {
        ...state.entities,
        [recommendationID]: {
          ...state.entities[recommendationID],
          [action]: false,
          actions: state.entities[recommendationID].actions.filter(({ id }) => id !== actionID),
        },
      },
    };

    return newState;
  }),
  on(UpdateCarouselPage, (state: RecommendationState, {carouselPageIndex}) => {
    return {
      ...state,
      carouselPageIndex,
  };
  }),
  on(UpdateSortOption, (state, { sortOption } ) => {
    return {
        ...state,
        sortOption,
  };
  }),
  on(UpdateListFilter, (state, {formValues}) => {
    return {
        ...state,
        listFilters: {
          ...formValues
        },
    };
  })
);

export function reducer(state: RecommendationState | undefined, action: Action) {
  return recommendationReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = recommendationAdapter.getSelectors();
