import { Component, OnInit, HostListener } from '@angular/core';
import { UserFacadeService } from 'sbx-ui-core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  showUserMenu: boolean;
  currentUserNickname$: Observable<string>;

  @HostListener('document:click')
  onClick() {
    this.showUserMenu = false;
  }

  constructor(private userFacadeService: UserFacadeService) {
    this.currentUserNickname$ = this.userFacadeService.getUserNickname();
  }

  ngOnInit() {}

  toggleUserMenu(event: MouseEvent) {
    event.stopPropagation();
    this.showUserMenu = !this.showUserMenu;
  }

  onLogoffClicked() {
    window.location.assign('http://account.sparkbox.co/logout');
  }
}
