import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as fromConfigActions from './config.actions';
import { ConfigService } from '../services/config.service';

@Injectable()
export class ConfigEffects {
  getConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromConfigActions.GetConfig),
      mergeMap(() =>
        this.configService.getConfig().pipe(
          map((config) => fromConfigActions.GetConfigSuccess({config})),
          catchError(error => of(fromConfigActions.GetConfigFailure({ error })))
        )
      )
    )
  );

  updateConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromConfigActions.UpdateConfig),
      mergeMap(({params}) =>
        this.configService.updateConfig(params).pipe(
          map((config) => fromConfigActions.UpdateConfigSuccess({config})),
          catchError(error => of(fromConfigActions.UpdateConfigFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private configService: ConfigService) {}

}
