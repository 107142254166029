import { getSelectors, RouterReducerState, routerReducer } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';

import * as fromRecommendation from './features/recommendation/state';
import * as fromConfig from './features/settings/state/config.reducer';

export interface State {
  recommendation: fromRecommendation.RecommendationState;
  router: RouterReducerState;
  config: fromConfig.ConfigState;
}

export const reducers = {
  router: routerReducer,
  recommendation: fromRecommendation.reducer,
  config: fromConfig.reducer
};

export const selectRouter = createFeatureSelector<State, RouterReducerState<any>>('router');

export const { selectQueryParams, selectRouteParams, selectRouteData, selectUrl } = getSelectors(selectRouter);
