import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-target-dates-cell-editor',
  templateUrl: './target-dates-cell-editor.component.html',
  styleUrls: ['./target-dates-cell-editor.component.scss']
})
export class TargetDatesCellEditorComponent implements OnInit {
  params: any;
  value;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
  }

  ngOnInit() {
  }

  onChange(event) {
    this.params.data.changed = true;
    this.params.data.targetDate = this.value;
    this.params.api.refreshCells();
  }

}
