import { getCurrencySymbol } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { currencyFormatter, currencyFormatterNoCents } from 'projects/sbx-ui-planning/src/app/infra/datagrid/datagrid.formatters';
import { filter, first } from 'rxjs/operators';
import { UserFacadeService } from 'sbx-ui-core';
import { Recommendation } from '../../../../../../../../../api/abstracts/insights';
import { RecommendationOptionsGridActionCellRendererComponent } from '../recommendation-options-grid-action-cell-renderer/recommendation-options-grid-action-cell-renderer.component';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-recommendation-summary-grid',
  templateUrl: './recommendation-summary-grid.component.html',
  styleUrls: ['./recommendation-summary-grid.component.scss'],
})
export class RecommendationSummaryGridComponent implements OnInit {
  @Input()
  recommendationOptions: {
    currentPrice: number;
    recommendedPrice: number;
    cashMarginIncrease: number;
    terminalStockDecrease: number;
    totalInventory: number;
    lastWeekCover: number;
  }[];

  columnDefs: ColDef[];

  gridOptions: GridOptions = {
    onGridReady: ({ api }) => api.sizeColumnsToFit(),
    rowHeight: 48,
    headerHeight: 62,
    getRowClass: params => {
      if (params.data.recommended) {
        return 'recommended';
      }
    },
  };

  constructor(private userFacadeService: UserFacadeService, private decimalPipe: DecimalPipe) {}

  ngOnInit() {
    this.userFacadeService
      .getUserCurrency()
      .pipe(
        filter(currency => !!currency),
        first()
      )
      .subscribe(currency => {
        const userCurrencySymbol = getCurrencySymbol(currency, 'narrow');
        this.columnDefs = [
          {
            headerName: '',
            field: 'region',
          },
          {
            headerName: 'Current Price',
            field: 'currentPrice',
            width: 100,
            valueFormatter: ({ value }) => currencyFormatter(userCurrencySymbol)({ value }),
          },
          {
            headerName: 'Recommended Price',
            field: 'recommendedPrice',
            width: 100,
            valueFormatter: ({ value }) => currencyFormatter(userCurrencySymbol)({ value }),
          },
          {
            headerName: 'Projected Margin Benefit',
            field: 'cashMarginIncrease',
            width: 100,
            valueFormatter: ({ value }) => currencyFormatterNoCents(userCurrencySymbol)({ value }),
            cellStyle(params) {
              if (params.value > 0) {
                return { color: '#00cc00' };
              } else {
                return null;
              }
            },
          },
          {
            headerName: 'Projected Stock Benefit',
            field: 'terminalStockDecrease',
            width: 100,
            valueFormatter: ({ value }) => `${value} u`,
            cellStyle(params) {
              if (params.value < 0) {
                return { color: '#00cc00' };
              } else {
                return null;
              }
            },
          },
          {
            headerName: 'Total Inventory',
            field: 'totalInventory',
            width: 100,
            valueFormatter: ({ value }) => `${value} u`,
          },
          {
            headerName: 'Last Week Cover',
            field: 'lastWeekCover',
            width: 100,
            valueFormatter: ({ value }) => `${this.decimalPipe.transform(value, '0.0-1')} weeks`,
          },
          {
            headerName: 'Action',
            suppressSizeToFit: false,
            width: 100,
            cellRendererFramework: RecommendationOptionsGridActionCellRendererComponent,
          },
        ];
      });
  }
}
