import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { RefFacadeService, UserFacadeService } from 'sbx-ui-core';
import { Observable, combineLatest } from 'rxjs';
import { map, first, withLatestFrom, filter } from 'rxjs/operators';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import {
  FIRST_EVENT_TYPES,
  OPTIMISATION_METHODS,
  PRICE_SCENARIOS_OPTIONS,
  USER_SETTINGS_LIST,
  CONFIG_SETTINGS_LIST,
  ORG_SETTINGS_LIST
} from '../../../../../../../api/abstracts/insights/constants';
import { ConfigFacadeService } from './services/config-facade.service';
import { Region } from '../../../../../../../api/abstracts/shared/region';
import { MH } from '../../../../../../../api/abstracts/shared/mh';
import { DecimalPipe } from '@angular/common';
import { getCurrencySymbol } from '@angular/common';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  departments$: Observable<any>;
  seasons$: Observable<any>;
  collections$: Observable<any>;
  insightDepartments$: Observable<string[]>;
  insightSeasons$: Observable<string[]>;
  insightCollections$: Observable<string[]>;
  departments = [];
  seasons = [];
  collections = [];
  selectedSetting = '';
  userSettingsList = USER_SETTINGS_LIST;
  configSettingsList = CONFIG_SETTINGS_LIST;
  orgSettingsList = ORG_SETTINGS_LIST;
  regionColumnDefs: ColDef[];
  regionTargetDateGrid: GridApi;
  minAgeValue: number;
  maxAgeValue: number;
  floor = 1;
  ceil = 105;
  allMerchHierarchy$: Observable<MH[]>;
  gridOptions: GridOptions = {
    rowHeight: 35,
    headerHeight: 40,
  };

  regionTargetDateGridOptions: GridOptions = {
    rowHeight: 35,
    headerHeight: 40,
    context: {
      parentComponent: this,
    },
    onGridReady: (e) => {
      this.regionTargetDateGrid = e.api;
    },
    onCellValueChanged: (params) => {
      params.data.changed = true;
      params.api.refreshCells();
      params.api.redrawRows();
    },
  };
  preEventWeeks: number;
  inventoryMin: number;
  firstSeason: string[];
  lastSeason: string[];
  maximumDiscount: number;
  minPriceChangeDiscount: number;
  minimumDiscount: number;
  minimumMargin: number;
  nsalesMin: number;
  optimisationMethod: string;
  optimisationTarget: number;
  priceEvent1Type: string;
  priceEvent2Weeks: number;
  priceScenarios: string[];
  regionTargetDates: { [key: string]: string };
  sellthroughMax: number;
  weeksCover: number;
  weeksSinceLastPriceChange: number;
  firstEventType = FIRST_EVENT_TYPES;
  optimisationMethods = OPTIMISATION_METHODS;
  priceScenariosOptions =  PRICE_SCENARIOS_OPTIONS;
  selectedRegions: string[] = [];
  regions$: Observable<Region[]>;
  configRegions: string[];
  selectedFirstSeason: string[];
  selectedLastSeason: string[];
  minimumUnitImprovement: number;
  minimumUnitPercentImprovement: number;
  minimumMarginImprovement: number;
  minimumMarginPercentImprovement: number;
  userCurrency$: Observable<string>;

  constructor(private refFacadeService: RefFacadeService, private userFacadeService: UserFacadeService,
              private configFacadeService: ConfigFacadeService, private decimalPipe: DecimalPipe) {
    this.departments$ = this.refFacadeService.getMerchHierarchy().pipe(
      filter(depts => !!depts),
      map(depts => _.orderBy(depts, ['h2_desc'], ['asc']))
    );
    this.seasons$ = this.refFacadeService.getSeason().pipe(map(seasons => _.orderBy(seasons, 'season_desc')));
    this.collections$ = this.refFacadeService.getCollection().pipe(map(collections => _.orderBy(collections, 'collections_desc')));
    this.regions$ = this.refFacadeService.getRegion().pipe(map(region => _.orderBy(region, 'description')));
    this.insightDepartments$ = this.userFacadeService.getUserInsightDepartments();
    this.insightSeasons$ = this.userFacadeService.getUserInsightSeasons();
    this.insightCollections$ = this.userFacadeService.getUserInsightCollections();
    this.userFacadeService.getUserInsightMaxProductAge().pipe(first()).
    subscribe(age => this.maxAgeValue = +age);
    this.userFacadeService.getUserInsightMinProductAge().pipe(first()).
    subscribe(age => this.minAgeValue = +age);

    combineLatest([this.insightDepartments$, this.departments$])
      .pipe(
        filter(([insightDepartments, depts]) => !!insightDepartments && !!depts),
        first()
      )
      .subscribe(([insightDepartments, depts]) => {
        this.departments = _(depts)
          .filter(({ h2_id }) => insightDepartments.includes(h2_id))
          .orderBy(['h2_desc'], ['asc'])
          .value();
      });

    combineLatest([this.insightSeasons$, this.seasons$])
      .pipe(
        filter(([insightSeasons, seasons]) => !!insightSeasons && seasons.length > 0),
        first()
      )
      .subscribe(([insightSeasons, seasons]) => {
        this.seasons = _(seasons)
          .filter(({ season_desc }) => insightSeasons.includes(season_desc))
          .orderBy(['season_desc'], ['asc'])
          .value();
      });

    combineLatest([this.insightCollections$, this.collections$])
      .pipe(
        filter(([insightCollections, collections]) => !!insightCollections && collections.length > 0),
        first()
      )
      .subscribe(([insightCollections, collections]) => {
        this.collections = _(collections)
          .filter(({ collection_desc }) => insightCollections.includes(collection_desc))
          .orderBy(['collection_desc'], ['asc'])
          .value();
      });

    this.allMerchHierarchy$ = this.refFacadeService.getAllMerchHierarchy().pipe((map(allMerchHeirarchy =>
       _.orderBy(_.unionBy(allMerchHeirarchy, 'h2_desc'), 'h2_desc')
       )));
    this.configFacadeService.getConfig().pipe(first()).subscribe(config => {
          this.preEventWeeks = config.preEventWeeks;
          this.inventoryMin = config.inventoryMin;
          this.firstSeason = config.firstSeason;
          this.lastSeason = config.lastSeason;
          this.maximumDiscount = config.maximumDiscount;
          this.minPriceChangeDiscount = config.minPriceChangeDiscount;
          this.minimumDiscount = config.minimumDiscount;
          this.minimumMargin = config.minimumMargin;
          this.nsalesMin = config.nsalesMin;
          this.optimisationMethod = config.optimisationMethod;
          this.optimisationTarget = config.optimisationTarget;
          this.priceEvent1Type = config.priceEvent1Type;
          this.priceEvent2Weeks = config.priceEvent2Weeks;
          this.priceScenarios = config.priceScenarios;
          this.regionTargetDates = config.regionTargetDates;
          this.configRegions = config.regions;
          this.sellthroughMax = config.sellthroughMax;
          this.weeksCover = config.weeksCover;
          this.weeksSinceLastPriceChange = config.weeksSinceLastPriceChange;
          this.minimumUnitImprovement = config.minimumUnitImprovement;
          this.minimumUnitPercentImprovement = config.minimumUnitPercentImprovement;
          this.minimumMarginImprovement = config.minimumMarginImprovement;
          this.minimumMarginPercentImprovement = config.minimumMarginPercentImprovement;
        }
      );
    this.selectedFirstSeason = this.firstSeason;
    this.selectedLastSeason = this.lastSeason;
  }

  ngOnInit() {
    this.userCurrency$ = this.userFacadeService.getUserCurrency().pipe(filter(currency => !!currency),
    first(),
    map((currency) => getCurrencySymbol(currency, 'narrow')
    ));
  }

  onChangeDept() {
    this.userFacadeService.updateUserInsightDepartments(this.departments.map(({ h2_id }) => h2_id));
  }

  onChangeSeason() {
    this.userFacadeService.updateUserInsightSeasons(this.seasons.map(({ season_desc }) => season_desc));
  }

  onChangeCollection() {
    this.userFacadeService.updateUserInsightCollections(this.collections.map(({ collection_desc }) => collection_desc));
  }

  selectSetting(setting) {
    this.selectedSetting = setting;
  }

  onMinAgeChange(e) {
    this.minAgeValue = e;
    this.userFacadeService.updateUserInsightMinProductAge(+e);
  }

  onMaxAgeChange(e) {
    this.maxAgeValue = e;
    this.userFacadeService.updateUserInsightMaxProductAge(+e);
  }

  addRegion() {
    const regionsInGrid = [];
    this.regionTargetDateGrid.forEachNode((row) => {
      regionsInGrid.push(row.data.region);
    });
    this.selectedRegions.forEach(region => {
        if (!regionsInGrid.includes(region)) {
          this.regionTargetDateGrid.addItems([{ region, targetDate: null , new: true}]);
        }
    });
  }

  updateChanges(property, value) {
    const params = {};
    if (property !== 'minimumMargin' && value) {
      switch (property) {
        case 'optimisationTarget':
        case 'minimumDiscount':
        case 'maximumDiscount':
        case 'minimumMargin':
        case 'sellthroughMax':
        case 'minPriceChangeDiscount':
        case 'minimumUnitPercentImprovement':
        case 'minimumMarginPercentImprovement': {
          params[property] = +this.decimalPipe.transform(value, '0.1-2');
          break;
        }
        case 'regionTargetDates': {
          params[property] = value;
          params['regions'] = _.keys(value);
          break;
        }
        default: {
          params[property] = value;
        }
      }
    } else if (property === 'minimumMargin') {
      params[property] =  value === '' ? null : +this.decimalPipe.transform(value, '0.1-2');
    }
    this.configFacadeService.updateConfig(params);
  }

  onSelectChange(property, event) {
    const params = {};
    params[property] = event.value;
    this.configFacadeService.updateConfig(params);
  }
}
