import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import * as fromRecommendationActions from '../state/recommendation.actions';
import { Observable, of } from 'rxjs';
import { Recommendation } from '../../../../../../../../api/abstracts/insights/recommendation.model';
import { getAll, getEntities, getAllAfterLoad, getEntity } from '../state/recommendation.selectors';
import { map, filter } from 'rxjs/operators';
import { Dictionary } from '@ngrx/entity';

import * as _ from 'lodash';
import { RecommendationWithForecast } from '..';

@Injectable({
  providedIn: 'root',
})
export class RecommendationFacadeService {
  constructor(private store: Store<fromRoot.State>) {}

  loadAll() {
    this.store.dispatch(fromRecommendationActions.LoadAll());
  }

  loadForecast(id: number) {
    this.store.dispatch(fromRecommendationActions.LoadForecast({ id }));
  }

  getAll(): Observable<RecommendationWithForecast[]> {
    return this.store.pipe(select(getAllAfterLoad));
  }

  getEntities(): Observable<Dictionary<RecommendationWithForecast>> {
    return this.store.pipe(select(getEntities));
  }

  get(id: number): Observable<RecommendationWithForecast> {
    return this.store.pipe(select(getEntity(id)));
  }

  getAllRecommended(): Observable<RecommendationWithForecast[]> {
    return this.getAll().pipe(
      filter(recs => !!recs),
      map(recs => recs.filter(rec => rec.recommended))
    );
  }

  getAllRelated(id: number): Observable<{ rec: RecommendationWithForecast; related: RecommendationWithForecast[] }> {
    return this.getEntities().pipe(
      map(recs => {
        const rec = recs[id];
        if (rec) {
          const { sku_id } = rec;

          return {
            rec,
            related: _(recs)
              .filter(d => d.sku_id === sku_id)
              .value(),
          };
        }

        return { rec: null, related: [] };
      })
    );
  }

  likeRecommendation(id: number) {
    this.store.dispatch(fromRecommendationActions.Like({ id }));
  }

  unlikeRecommendation(actionID: number, id: number) {
    this.store.dispatch(fromRecommendationActions.Unlike({ actionID, id }));
  }

  takeRecommendation(id: number) {
    this.store.dispatch(fromRecommendationActions.Take({ id }));
  }

  untakeRecommendation(actionID: number, id: number) {
    this.store.dispatch(fromRecommendationActions.Untake({ actionID, id }));
  }

  binRecommendation(id: number) {
    this.store.dispatch(fromRecommendationActions.Bin({ id }));
  }

  unbinRecommendation(actionID: number, id: number) {
    this.store.dispatch(fromRecommendationActions.Unbin({ actionID, id }));
  }

  loadProductHistory(regionRecsIds: number[]) {
    const id = _.first(regionRecsIds);
    this.store.dispatch(fromRecommendationActions.LoadProductHistory({ id: +id, regionRecsIds }));
  }
}
