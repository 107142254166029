export const ACTION_LIKE = 'like';
export const ACTION_TAKE = 'take';
export const ACTION_BIN = 'bin';
export const INCREASE_PRICE = 'increase price';
export const REMOVE_DISCOUNT = 'remove discount';
export const REDUCE_PRICE_RECOMMENDATION_TYPE = 'pricedown';
export const REDUCE_PRICE_CP_RECOMMENDATION_TYPE = 'pricedownCP';
export const REDUCE_PRICE_P_RECOMMENDATION_TYPE = 'pricedownP';
export const DO_NOTHING_RECOMMENDATION_TYPE = 'carryforward';
export const STATUS_OPEN = 'open';
export const ACTION_1 = 'event_1';
export const ACTION_2 = 'event_2';
export const NO_ACTION_DESCRIPTION = 'No action';
export const CARRY_TO_NEXT_SEASON_DESCRIPTION = 'Carry forward';
export const REDUCE_PRICE = 'reduce price';
export const FIRST_EVENT_TYPES = ['PRM', 'MDN'];
export const OPTIMISATION_METHODS  = ['net_eol', 'net_meet', 'net_close', 'gross_eol', 'gross_meet', 'gross_close'];
export const PRICE_SCENARIOS_OPTIONS  =  ['carry_forward', 'price_down_CP', 'price_down_P', 'price_down'];
export const USER_SETTINGS_LIST  = ['Departments', 'Seasons', 'Collection', 'Age'];
export const CONFIG_SETTINGS_LIST =  ['Regions, Events and Dates', 'Pricing and Targets', 'Selection Criteria'];
export const MARGIN_SORT = 'cash_margin_increase';
export const VOLUME_SORT = 'volume_increase';
export const DEFAULT_SELECTED_REGION = 'FR';
export const ORG_SETTINGS_LIST = ['Disposal Cost']