import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreviousUrlService {
  previousUrl$: BehaviorSubject<string> = new BehaviorSubject(null);
  constructor() { }

  getPreviousUrl(): BehaviorSubject<string> {
    return this.previousUrl$;
  }
}
