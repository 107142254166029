import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { RecommendationWithForecast } from '../../state';
import {
  DO_NOTHING_RECOMMENDATION_TYPE,
  NO_ACTION_DESCRIPTION,
  REDUCE_PRICE_CP_RECOMMENDATION_TYPE,
  CARRY_TO_NEXT_SEASON_DESCRIPTION,
} from '../../../../../../../../../api/abstracts/insights';
import * as _ from 'lodash';
import { UserFacadeService } from 'sbx-ui-core';
import { filter, first } from 'rxjs/operators';
import { roundingFormatter } from 'projects/sbx-ui-planning/src/app/infra/datagrid/datagrid.formatters';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-recommendation-options-graph',
  templateUrl: './recommendation-options-graph.component.html',
  styleUrls: ['./recommendation-options-graph.component.scss']
})
export class RecommendationOptionsGraphComponent implements OnInit {
  readonly noAction = NO_ACTION_DESCRIPTION;
  readonly carryForward = CARRY_TO_NEXT_SEASON_DESCRIPTION;
  @Input()
  params;

  @Input()
  recommendationOptions: RecommendationWithForecast[];

  @Output()
  switchToGrid = new EventEmitter();

  netSalesUnitsChartData;
  inventoryChartData;
  cumulativeChartData;
  lineChartColors = [
    {
      borderColor: '#6f6f6f',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: '#6f6f6f',
      pointHoverBorderColor: '#6f6f6f'
    },
    {
      borderColor: '#bdb7b7',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#bdb7b7'
    },
    {
      borderColor: '#ffa3c7',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
  ];
  cummulativeChartColors = [{
    borderColor: '#bdb7b7',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: '#bdb7b7'
  },
  {
    borderColor: '#ffa3c7',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  },
  ];
  recLabel: string;
  userCurrency$: Observable<string>;
  lineChartWidth = 412;
  lineChartHeight = 300;
  constructor(private userFacadeService: UserFacadeService) { }

  ngOnInit() {
    this.userCurrency$ = this.userFacadeService.getUserCurrency().pipe(filter(currency => !!currency));
    if (this.params.recommendation_type === DO_NOTHING_RECOMMENDATION_TYPE) {
      this.recLabel = CARRY_TO_NEXT_SEASON_DESCRIPTION;
    } else if (this.params.recommendation_type === REDUCE_PRICE_CP_RECOMMENDATION_TYPE) {
      this.recLabel = NO_ACTION_DESCRIPTION;
    } else if (this.params.action_price < this.params.current_price) {
      this.recLabel = `Reduce price to `;
    } else {
      this.recLabel = `Increase price to `;
    }
    let forecastNetSalesUnits = this.params.forecast.map(({fcst_net_sales_u, cal_date}) => {
      return {
        x: moment(cal_date).format('MM/DD/YY'),
        y: fcst_net_sales_u
      };
    });
    const firstForecastDate =  _.minBy(this.params.forecast.map(({cal_date}) => cal_date));
    const actualNetSales = this.params.productHistory.filter(({ cal_date }) =>
      moment(cal_date).format('MM/DD/YY') >= moment(firstForecastDate).subtract(4, 'weeks').format('MM/DD/YY')
      ).map(({ net_sales_u, cal_date }) => {
      return {
        x: moment(cal_date).format('MM/DD/YY'),
        y: net_sales_u
      };
    });
    const actualInventory = this.params.productHistory.filter(({ cal_date }) =>
      moment(cal_date).format('MM/DD/YY') >= moment(firstForecastDate).subtract(4, 'weeks').format('MM/DD/YY')
      ).map(({ total_inventory_u, cal_date }) => {
      return {
        x: moment(cal_date).format('MM/DD/YY'),
        y: total_inventory_u
      };
    });
    const noActionData = _.first(this.recommendationOptions.filter(({recommendation_type}) =>
     recommendation_type === REDUCE_PRICE_CP_RECOMMENDATION_TYPE));
    let noActionNetSalesUnits = noActionData.forecast.map((data) => {
      return {
        x: moment(data.cal_date).format('MM/DD/YY'),
        y: data.fcst_net_sales_u
      };
    });
    let noActionInventory = noActionData.forecast.map((data) => {
      return {
        x: moment(data.cal_date).format('MM/DD/YY'),
        y: data.fcst_end_stock_u
      };
    });
    let forecastInventory =  this.params.forecast.map(({fcst_end_stock_u, cal_date}) =>  {
      return {
        x: moment(cal_date).format('MM/DD/YY'),
        y: fcst_end_stock_u
      };
    });
    forecastInventory = [_.last(actualInventory), ...forecastInventory];
    noActionInventory = [_.last(actualInventory), ...noActionInventory];
    forecastNetSalesUnits = [_.last(actualNetSales), ...forecastNetSalesUnits];
    noActionNetSalesUnits = [_.last(actualNetSales), ...noActionNetSalesUnits];
    const forecastCummulativeMargin = this.params.forecast.map(
      ({fcst_cum_margin, cal_date}) => {
        return {
          x: moment(cal_date).format('MM/DD/YY'),
          y: fcst_cum_margin
        };
      }
    );
    const noActionCummulativeMargin = noActionData.forecast.map((data ) => {
      return {
        x: moment(data.cal_date).format('MM/DD/YY'),
        y: data.fcst_cum_margin
      };
    });

    this.netSalesUnitsChartData = {
      title : 'Net Sales Units',
      lineChartData: [
        { data: actualNetSales, label: 'Actual', fill: false },
        { data: noActionNetSalesUnits, label: 'No Action', fill: false, borderDash: [2, 2] },
        { data: (this.recLabel !== NO_ACTION_DESCRIPTION) ? forecastNetSalesUnits : '', label: this.recLabel,
          borderDash: [2, 2], fill: false }
      ],
      legend: false,
    };
    this.inventoryChartData = {
      title : 'Inventory',
      lineChartData: [
        { data: actualInventory, label: 'Actual', fill: false },
        { data: noActionInventory, label: 'No Action', fill: false, borderDash: [2, 2] },
        { data: (this.recLabel !== NO_ACTION_DESCRIPTION) ? forecastInventory : '', label: this.recLabel,
          borderDash: [2, 2], fill: false }
      ],
      legend: false,
    };
    this.cumulativeChartData = {
      title : 'Cummulative Margin',
      lineChartData: [
        { data: noActionCummulativeMargin, label: 'No Action', fill: false, borderDash: [2, 2] },
        { data: (this.recLabel !== NO_ACTION_DESCRIPTION) ? forecastCummulativeMargin : '', label: this.recLabel,
          borderDash: [2, 2], fill: false }
      ],
      legend: false,
    };
  }

}
