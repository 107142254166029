import { Component, OnInit } from '@angular/core';

import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, tap, first, last } from 'rxjs/operators';
import * as _ from 'lodash';

import { Recommendation } from '../../../../../../../../../api/abstracts/insights/recommendation.model';

import { RecommendationFacadeService } from '../../services/recommendation-facade.service';

import { UserFacadeService } from 'sbx-ui-core';

import {  MARGIN_SORT, VOLUME_SORT, DEFAULT_SELECTED_REGION } from '../../../../../../../../../api/abstracts/insights';
import { SharedUIFacadeService } from '../../services/shared-ui-facade.service';


@Component({
  selector: 'app-recommendation-overview',
  templateUrl: './recommendation-overview.component.html',
  styleUrls: ['./recommendation-overview.component.scss'],
})
export class RecommendationOverviewComponent implements OnInit {
  recommendations$: Observable<Recommendation[]>;
  regionSelected$: BehaviorSubject<string> = new BehaviorSubject(null);
  currentUserName$: Observable<string>;
  regions$: Observable<string[]>;

  greeting: 'good morning' | 'good afternoon' | 'good evening';

  regionSelectionDropdownOpen = false;
  sortOptionChanged$: Observable<string>;
  page$: Observable<number>;
  constructor(private recommendationFacadeService: RecommendationFacadeService, private userFacadeService: UserFacadeService,
              private sharedUIFacadeService: SharedUIFacadeService) {
    this.recommendationFacadeService.loadAll();
  }

  ngOnInit() {
    this.currentUserName$ = this.userFacadeService.getUserFullName().pipe(
      filter(fullName => !!fullName),
      map(fullName => fullName.split(' ')[0])
    );
    this.setGreeting();

    const regionSelected$ = this.regionSelected$.pipe(filter(d => !!d));
    const userRecs$ = combineLatest([
      this.recommendationFacadeService.getAllRecommended(),
      this.userFacadeService.getUserInsightSeasons().pipe(filter(seasons => !!seasons)),
      this.userFacadeService.getUserInsightDepartments().pipe(filter(departments => !!departments)),
      this.userFacadeService.getUserInsightCollections().pipe(filter(collections => !!collections)),
      this.userFacadeService.getUserInsightMaxProductAge().pipe(filter(maxAge => !!maxAge)),
      this.userFacadeService.getUserInsightMinProductAge().pipe(filter(minAge => !!minAge)),
    ]).pipe(
      map(([recs, userInsightSeasonDescs, userInsightDepartmentIDs, userInsightCollectionDescs, maxAge, minAge]) => {
        return recs.filter(rec => userInsightSeasonDescs.includes(rec.season_desc) && userInsightDepartmentIDs.includes(rec.department_id)
        && userInsightCollectionDescs.includes(rec.collection_desc) && (rec.age <= +maxAge ) && (rec.age >= +minAge));
      }
      )
    );
    this.regions$ = userRecs$.pipe(
      map(recs =>
        _(recs)
          .map(({ region }) => region)
          .uniq()
          .value()
      )
    );

    this.regions$.pipe(first()).subscribe(regions => {
      if (regions.includes(DEFAULT_SELECTED_REGION)) {
        return this.regionSelected$.next(DEFAULT_SELECTED_REGION);
      }
      return this.regionSelected$.next(_.first(regions));
    });

    this.page$ = this.sharedUIFacadeService.getCarouselPageIndex();
    this.sortOptionChanged$ = this.sharedUIFacadeService.getSortOption();

    this.recommendations$ = combineLatest([userRecs$, regionSelected$, this.sortOptionChanged$]).pipe(
      map(([recs, region, sortOption]) => {
        const filteredRecs = (recs.filter(rec => rec.region === region && !rec.bin));
        return _.orderBy(filteredRecs, sortOption, [ 'desc'] );
      })
    );
  }

  selectRegion(region: string) {
    this.regionSelected$.next(region);
  }

  prevPage(page) {
    page = page - 1;
    this.sharedUIFacadeService.updateCarouselPageIndex(page);
  }

  nextPage(page) {
    page = page + 1;
    this.sharedUIFacadeService.updateCarouselPageIndex(page);
  }

  private setGreeting() {
    const now = new Date();
    const hour = now.getHours();

    if (hour > 16) {
      this.greeting = 'good evening';
    } else if (hour > 11) {
      this.greeting = 'good afternoon';
    } else {
      this.greeting = 'good morning';
    }
  }

  sortBy(option) {
    this.sharedUIFacadeService.updateSortOption(option);
  }
}
