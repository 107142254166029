import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as fromRecommendationActions from './recommendation.actions';
import { RecommendationService } from '../services';

@Injectable()
export class RecommendationEffects {
  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecommendationActions.LoadAll),
      mergeMap(() =>
        this.recommendationService.loadAll().pipe(
          map(entities => fromRecommendationActions.LoadAllSuccess({ entities })),
          catchError(error => of(fromRecommendationActions.LoadAllFailure({ error })))
        )
      )
    )
  );

  loadForecast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecommendationActions.LoadForecast),
      mergeMap(({ id }) =>
        this.recommendationService.loadForecast(id).pipe(
          map(entities => fromRecommendationActions.LoadForecastSuccess({ entities, id })),
          catchError(error => of(fromRecommendationActions.LoadForecastFailure({ error })))
        )
      )
    )
  );

  like$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecommendationActions.Like),
      mergeMap(({ id }) =>
        this.recommendationService.like(id).pipe(
          map(action => fromRecommendationActions.LikeSuccess({ action })),
          catchError(error => of(fromRecommendationActions.LikeFailure({ error })))
        )
      )
    )
  );

  unlike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecommendationActions.Unlike),
      mergeMap(({ actionID, id }) =>
        this.recommendationService.removeAction(actionID).pipe(
          map(() => fromRecommendationActions.UnlikeSuccess({ actionID, id })),
          catchError(error => of(fromRecommendationActions.UnlikeFailure({ error })))
        )
      )
    )
  );

  take$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecommendationActions.Take),
      mergeMap(({ id }) =>
        this.recommendationService.take(id).pipe(
          map(action => fromRecommendationActions.TakeSuccess({ action })),
          catchError(error => of(fromRecommendationActions.TakeFailure({ error })))
        )
      )
    )
  );

  untake$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecommendationActions.Untake),
      mergeMap(({ actionID, id }) =>
        this.recommendationService.removeAction(actionID).pipe(
          map(() => fromRecommendationActions.UntakeSuccess({ actionID, id })),
          catchError(error => of(fromRecommendationActions.UntakeFailure({ error })))
        )
      )
    )
  );

  bin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecommendationActions.Bin),
      mergeMap(({ id }) =>
        this.recommendationService.bin(id).pipe(
          map(action => fromRecommendationActions.BinSuccess({ action })),
          catchError(error => of(fromRecommendationActions.BinFailure({ error })))
        )
      )
    )
  );

  unbin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecommendationActions.Unbin),
      mergeMap(({ actionID, id }) =>
        this.recommendationService.removeAction(actionID).pipe(
          map(() => fromRecommendationActions.UnbinSuccess({ actionID, id })),
          catchError(error => of(fromRecommendationActions.UnbinFailure({ error })))
        )
      )
    )
  );

  loadProductHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecommendationActions.LoadProductHistory),
      mergeMap(({ id, regionRecsIds }) =>
        this.recommendationService.loadProductHistory(id).pipe(
          map(entities => fromRecommendationActions.LoadProductHistorySuccess({ entities, id, regionRecsIds })),
          catchError(error => of(fromRecommendationActions.LoadProductHistoryFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private recommendationService: RecommendationService) {}
}
