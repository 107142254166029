import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ChangeContext, LabelType, Options } from 'ng5-slider';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnChanges {

  constructor() { }

  @Input()
  minValue;

  @Input()
  maxValue;

  @Input()
  floor;

  @Input()
  ceil;

  @Input()
  reset;

  @Output()
  valueChange = new EventEmitter();

  @Output()
  highValueChange = new EventEmitter();

  options: Options;
  sliderControl: FormControl ;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reset && changes.reset.currentValue ) {
      this.sliderControl.reset([this.floor, this.ceil]);
    }
  }

  ngOnInit() {
    this.sliderControl = new FormControl([this.minValue, this.maxValue]);
    this.options = {
      floor: this.floor,
      ceil: this.ceil,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Ceil: {
            if (this.ceil > 99) {
              return '99+';
            } else  {
              return '' + value;
            }
          }
          default:
            return '' + value;
        }
      }
    };
  }

  onUserChangeEnd(changeContext: ChangeContext): void {
   if (changeContext.pointerType === 0) {
      this.valueChange.emit(changeContext.value);
    } else {
      this.highValueChange.emit(changeContext.highValue);
    }
  }

}
