import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { DisposalCostCellEditorComponent } from './disposal-cost-cell-editor/disposal-cost-cell-editor.component';

@Component({
  selector: 'app-disposal-cost-grid',
  templateUrl: './disposal-cost-grid.component.html',
  styleUrls: ['./disposal-cost-grid.component.scss']
})
export class DisposalCostGridComponent implements OnInit {
  @Input()
  departments;

  columnDefs: ColDef[];

  gridOptions: GridOptions = {
    rowHeight: 35,
    headerHeight: 40,
  };

  constructor() { }

  ngOnInit() {
    this.columnDefs = [
      { headerName: 'Departments', field: 'h2_desc', width: 350 },
      { headerName: 'Disposal Cost', field: 'disposal_cost', width: 150, cellRendererFramework: DisposalCostCellEditorComponent, },
    ];
  }

}
