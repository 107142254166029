import { Component, OnInit } from '@angular/core';
import { RecommendationFacadeService } from '../../services';
import {
  Recommendation,
  RecommendationType,
  RecommendationStatus,
} from '../../../../../../../../../api/abstracts/insights/recommendation.model';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { map, filter, first, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  REMOVE_DISCOUNT,
  INCREASE_PRICE,
  REDUCE_PRICE_P_RECOMMENDATION_TYPE,
  ACTION_LIKE,
  ACTION_TAKE,
  ACTION_BIN,
  STATUS_OPEN,
  REDUCE_PRICE,
  REDUCE_PRICE_RECOMMENDATION_TYPE,
} from '../../../../../../../../../api/abstracts/insights';
import { UserFacadeService } from 'sbx-ui-core';
import * as _ from 'lodash';
import { SharedUIFacadeService } from '../../services/shared-ui-facade.service';
import { FilterTypes } from '../../state';

@Component({
  selector: 'app-recommendation-list',
  templateUrl: './recommendation-list.component.html',
  styleUrls: ['./recommendation-list.component.scss'],
})

export class RecommendationListComponent implements OnInit {
  recommendations$: Observable<Recommendation[]>;
  departments$: Observable<{ h2_desc: string; h2_id: string }[]>;
  seasons$: Observable<string[]>;
  regions$: Observable<string[]>;
  collections$: Observable<string[]>;
  userMinAge$: Observable<string[]>;
  userMaxAge$: Observable<string[]>;
  sortOptionChanged$: Observable<string>;
  showFilterSideBar = false;
  animate = false;
  filterFormValues$: Observable<FilterTypes>;
  skuIds$: Observable<string[]>;
  searchValue$ = new BehaviorSubject({ skuId : null});
  constructor(
    private recommendationFacadeService: RecommendationFacadeService,
    private userFacadeService: UserFacadeService,
    private sharedUIFacadeService: SharedUIFacadeService
  ) {
    const userDepartments$ = this.userFacadeService.getUserInsightDepartments().pipe(filter(depts => !!depts));
    const userSeasons$ = this.userFacadeService.getUserInsightSeasons().pipe(filter(seasons => !!seasons));
    const userCollections$ = this.userFacadeService.getUserInsightCollections().pipe(filter(collections => !!collections));
    const userMaxAge$ = this.userFacadeService.getUserInsightMaxProductAge().pipe(filter(maxAge => !!maxAge));
    const userMinAge$ = this.userFacadeService.getUserInsightMinProductAge().pipe(filter(minAge => !!minAge));
    const userSettings$ = combineLatest([userDepartments$, userSeasons$, userCollections$, userMaxAge$, userMinAge$ ]).pipe(first());
    const allRecs$ = this.recommendationFacadeService.getAll().pipe(filter(d => !!d));
    const userRecs$ = userSettings$.pipe(
      switchMap(([userDepartments, userSeasons, userCollections, userMaxAge, userMinAge]) =>
        allRecs$.pipe(map(recs => recs.filter(rec => userDepartments.includes(rec.department_id) && userSeasons.includes(rec.season_desc) &&
        userCollections.includes(rec.collection_desc) && (rec.age <= +userMaxAge) && (rec.age >= +userMinAge)
        )))
      )
    );
    this.regions$ = userRecs$.pipe(
      map(recs =>
        _(recs)
          .map(({ region }) => region)
          .uniq()
          .value()
      )
    );

    this.skuIds$ = userRecs$.pipe(
      map(recs =>
        _(recs)
          .map(({ sku_id }) => sku_id)
          .uniq()
          .value()
      )
    );
    this.sortOptionChanged$ = this.sharedUIFacadeService.getSortOption().pipe(filter(option => !!option));
    this.filterFormValues$ = this.sharedUIFacadeService.getListFilters().pipe(filter(listFilters => !!listFilters));
    const searchValueChange$ = this.searchValue$.pipe(debounceTime(200), distinctUntilChanged());
    this.recommendations$ = combineLatest([
      userRecs$,
      this.sortOptionChanged$,
      this.filterFormValues$,
      searchValueChange$,
    ]).pipe(map(([ recs, sortOption, filtersList, searchValue]) => {
        let filteredRecs = recs.filter(({ recommended }) => recommended);
        if (filtersList.skuId && filtersList.skuId.length > 0) {
          filteredRecs = filteredRecs.filter(({ sku_id }) => filtersList.skuId.includes(sku_id));
        }
        if (filtersList.recommendation && filtersList.recommendation.length > 0) {
          const typeFilter = filtersList.recommendation.map(({ id }) => id);
          if (typeFilter.includes(REDUCE_PRICE)) {
            typeFilter.push(REDUCE_PRICE_RECOMMENDATION_TYPE, REDUCE_PRICE_P_RECOMMENDATION_TYPE);
          }
          filteredRecs = filteredRecs.filter(({ recommendation_type }) => typeFilter.includes(recommendation_type));
        }
        if (filtersList.department && filtersList.department.length > 0) {
          filteredRecs = filteredRecs.filter(({ department_id }) =>
          filtersList.department.map(({ h2_id }) => h2_id).includes(department_id));
        }
        if (filtersList.season && filtersList.season.length > 0) {
          filteredRecs = filteredRecs.filter(({ season_desc }) => filtersList.season.includes(season_desc));
        }
        if (filtersList.region && filtersList.region.length > 0) {
          filteredRecs = filteredRecs.filter(({ region }) => filtersList.region.includes(region));
        }
        if (filtersList.status && filtersList.status.length > 0) {
          const statusFilter = filtersList.status.map(({ id }) => id);
          if (statusFilter.includes(STATUS_OPEN)) {
            filteredRecs = filteredRecs.filter(({ take }) => !take);
          }
          if (statusFilter.includes(ACTION_LIKE)) {
            filteredRecs = filteredRecs.filter(({ like }) => like);
          }
          if (statusFilter.includes(ACTION_TAKE)) {
            filteredRecs = filteredRecs.filter(({ take }) => take);
          }
          if (statusFilter.includes(ACTION_BIN)) {
            filteredRecs = filteredRecs.filter(({ bin }) => bin);
          }
        }
        if (filtersList.collection && filtersList.collection.length > 0) {
          filteredRecs = filteredRecs.filter(({ collection_desc }) => filtersList.collection.includes(collection_desc));
        }
        if (filtersList.sliderMin) {
          filteredRecs = filteredRecs.filter(({ age }) =>  +filtersList.sliderMin <= age);
        }
        if (filtersList.sliderMax) {
          filteredRecs = filteredRecs.filter(({ age }) => +filtersList.sliderMax >= age);
        }
        if (searchValue.skuId) {
          filteredRecs = filteredRecs.filter(({ sku_id }) => sku_id.includes(searchValue.skuId));
        }
        return _.orderBy(filteredRecs, [sortOption, 'bin'], ['desc']);
      })
    );
  }

  ngOnInit() {
    setTimeout(() => {
      this.animate = true;
    }, 2000);
  }

  sortBy(option) {
    this.sharedUIFacadeService.updateSortOption(option);
  }

  toggleFilterSideBar() {
    this.showFilterSideBar = !this.showFilterSideBar;
  }

  closeSideBar() {
    this.showFilterSideBar = false;
  }

  onSearchValueChange(skuId) {
    this.searchValue$.next({ skuId });
  }

  clearSearchBar() {
    this.searchValue$.next({skuId: null});
  }
}
