import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RecommendationOverviewComponent, RecommendationDetailComponent } from './features/recommendation';
import { RecommendationListComponent } from './features/recommendation/components/recommendation-list/recommendation-list.component';
import { SettingsComponent } from './features/settings/settings.component';
import { ForecastGuard } from './features/recommendation/guards/forecast.guard';
import { ConfigGuard } from './features/settings/guards/config.guard';
import { ProductHistoryGuard } from './features/recommendation/guards/product-history.guard';

const routes: Routes = [
  {
    path: '',
    component: RecommendationOverviewComponent,
    canActivate: [],
  },
  {
    path: 'all',
    component: RecommendationListComponent,
    canActivate: [],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [ConfigGuard],
  },
  {
    path: ':id',
    component: RecommendationDetailComponent,
    canActivate: [ForecastGuard, ProductHistoryGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
