import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { InsightPredictionConfig } from '../../../../../../../../api/abstracts/insights/config.model';

import { ConfigService } from './config.service';
import { getConfig } from '../state/config.selectors';
import * as fromConfigActions from '../state/config.actions';

@Injectable({
  providedIn: 'root'
})
export class ConfigFacadeService {

  constructor(private configService: ConfigService, private store: Store<fromRoot.State>) { }

  getConfigAction() {
     this.store.dispatch(fromConfigActions.GetConfig());
  }

  getConfig(): Observable<any> {
    return this.store.pipe(select(getConfig));
  }

  updateConfig(params: Partial<InsightPredictionConfig>) {
    this.store.dispatch(fromConfigActions.UpdateConfig({params}));
  }
}
