import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-recommendation-image',
  templateUrl: './recommendation-image.component.html',
  styleUrls: ['./recommendation-image.component.scss'],
})
export class RecommendationImageComponent implements OnInit {
  @Input()
  imageUrl: string;

  constructor() {}

  ngOnInit() {}
}
