import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material';
import * as _ from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { Recommendation } from '../../../../../../../../../api/abstracts/insights/recommendation.model';
import {
  REMOVE_DISCOUNT,
  INCREASE_PRICE,
  REDUCE_PRICE_P_RECOMMENDATION_TYPE,
  ACTION_LIKE,
  ACTION_TAKE,
  ACTION_BIN,
  STATUS_OPEN,
  REDUCE_PRICE
} from '../../../../../../../../../api/abstracts/insights';
import { Observable, combineLatest, Subject } from 'rxjs';
import { RefFacadeService, UserFacadeService } from 'sbx-ui-core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SharedUIFacadeService } from '../../services/shared-ui-facade.service';

@Component({
  selector: 'app-recommendation-list-filter-sidebar',
  templateUrl: './recommendation-list-filter-sidebar.component.html',
  styleUrls: ['./recommendation-list-filter-sidebar.component.scss']
})
export class RecommendationListFilterSidebarComponent implements OnInit, OnDestroy {
  readonly increasePrice = INCREASE_PRICE;
  readonly reducePrice = REDUCE_PRICE;
  readonly removeDiscount = REMOVE_DISCOUNT;
  readonly pricedownP = REDUCE_PRICE_P_RECOMMENDATION_TYPE;
  readonly like = ACTION_LIKE;
  readonly take = ACTION_TAKE;
  readonly bin = ACTION_BIN;
  readonly open = STATUS_OPEN;

  recommendations$: Observable<Recommendation[]>;
  departments$: Observable<{ h2_desc: string; h2_id: string }[]>;
  userSeasons$: Observable<string[]>;
  userCollections$: Observable<string[]>;
  userMinAge$: Observable<string[]>;
  userMaxAge$: Observable<string[]>;
  resultMapping: {[k: string]: string} = {'=0': 'No result', '=1': '1 result', 'other': '# results'};
  showClearFilter = false;
  filtersForm: FormGroup;
  dropdownList = [];
  selectedItems = [];
  defaultSettings: IDropdownSettings = {};
  settings: IDropdownSettings = {};
  departmentSettings: IDropdownSettings = {};
  seasonSettings: IDropdownSettings = {};
  collectionSettings: IDropdownSettings = {};
  regionSettings: IDropdownSettings = {};
  statusSettings: IDropdownSettings = {};
  skuIdSettings: IDropdownSettings = {};
  recommendationTypes = [{ id: this.increasePrice, value: 'Increase price'}, {id: this.reducePrice, value: 'Reduce price'},
  {id: this.removeDiscount, value: 'Remove discount'}];
  status = [{ id: this.open, value: 'Open'}, {id: this.like, value: 'Liked'}, {id: this.take, value: 'Taken'},
  {id: this.bin, value: 'Binned'}];
  componentDestroy$ = new Subject();
  reset = false;
  sliderValues$: Observable<{ minValue: string, maxValue: string, ceil: string[], floor: string[] }>;
  @Input()
  regions: string[];

  @Input()
  skuIds: string[];

  @Input()
  result;

  @Input()
  filterFormValues;

  @Output()
  closeFilterSideBar = new EventEmitter();

  constructor(
    private refFacadeService: RefFacadeService,
    private userFacadeService: UserFacadeService,
    private sharedUIFacadeService: SharedUIFacadeService
  ) {
    const merchHierarchy$ = this.refFacadeService.getMerchHierarchy();
    const userDepartments$ = this.userFacadeService.getUserInsightDepartments().pipe(filter(depts => !!depts));
    this.userSeasons$ = this.userFacadeService.getUserInsightSeasons().pipe(filter(seasons => !!seasons));
    this.userCollections$ = this.userFacadeService.getUserInsightCollections().pipe(filter(collections => !!collections));
    this.userMaxAge$ = this.userFacadeService.getUserInsightMaxProductAge().pipe(filter(maxAge => !!maxAge));
    this.userMinAge$ = this.userFacadeService.getUserInsightMinProductAge().pipe(filter(minAge => !!minAge));
    this.departments$ = combineLatest([merchHierarchy$, userDepartments$]).pipe(
      map(([merchHierarchy, userDepartments]) => {
        const merchHierarchyMap = _.keyBy(merchHierarchy, 'h2_id');

        return userDepartments.map(id => merchHierarchyMap[id]);
      })
    );
    this.filtersForm = new FormGroup({
      skuId: new FormControl([]),
      recommendation: new FormControl([]),
      department: new FormControl([]),
      season: new FormControl([]),
      collection: new FormControl([]),
      region: new FormControl([]),
      status: new FormControl([]),
      sliderMin: new FormControl([]),
      sliderMax: new FormControl([]),
    });
   }

  ngOnInit() {

    this.defaultSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
    };
    this.settings = {
      ...this.defaultSettings,
      idField: 'id',
      textField: 'value',
    };
    this.departmentSettings = {
      ...this.defaultSettings,
      idField: 'h2_id',
      textField: 'h2_desc',
    };

    this.seasonSettings = {
      ...this.defaultSettings,
      idField: 'season',
      textField: 'season',
    };
    this.collectionSettings = {
      ...this.defaultSettings,
      idField: 'collection',
      textField: 'collection',
    };
    this.regionSettings = {
      ...this.defaultSettings,
      idField: 'region',
      textField: 'region',
    };
    this.skuIdSettings = {
      ...this.defaultSettings,
      allowSearchFilter: true,
      idField: 'skuId',
      textField: 'skuId',
    };
    this.filtersForm.patchValue(this.filterFormValues);
    this.sliderValues$ = combineLatest([this.userMaxAge$, this.userMinAge$]).pipe(map(([ceil, floor]) => {
      return {
        minValue: this.filterFormValues.sliderMin ? this.filterFormValues.sliderMin : floor,
        maxValue: this.filterFormValues.sliderMax ? this.filterFormValues.sliderMax : ceil,
        ceil,
        floor
      };
    }));
    this.filtersForm.valueChanges.pipe(takeUntil(this.componentDestroy$)
    ).subscribe((formValue) => {
      this.showClearFilter = true;
      this.sharedUIFacadeService.updateListFilters(formValue);
    });
  }

  ngOnDestroy(): void  {
    this.componentDestroy$.next();
  }

  onMinAgeChange(value) {
    this.filtersForm.get('sliderMin').setValue(value);
    this.reset = false;
  }

  onMaxAgeChange(value) {
    this.filtersForm.get('sliderMax').setValue(value);
    this.reset = false;
  }

  closeSideBar() {
   this.closeFilterSideBar.emit();
  }

  resetAllFilters() {
    this.filtersForm.reset();
    this.showClearFilter = false;
    this.reset = true;
  }
}
