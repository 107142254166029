import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InsightPredictionConfig } from '../../../../../../../../api/abstracts/insights/config.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private http: HttpClient) {
  }

  public updateConfig(params: Partial<InsightPredictionConfig>) {
    return this.http.post<InsightPredictionConfig>('/api/insight/config', params);
  }

  public getConfig(): Observable<InsightPredictionConfig> {
    return this.http.get<InsightPredictionConfig>('/api/insight/config').pipe(map(config => config));
  }
}
