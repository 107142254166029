import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import { filter, tap, first, switchMap, catchError } from 'rxjs/operators';
import { ConfigFacadeService } from '../services/config-facade.service';
import { config } from '@fullcalendar/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigGuard implements CanActivate {

  constructor(private configFacadeService: ConfigFacadeService ) { }

  getFromStoreOrAPI(): Observable<any> {
    return this.configFacadeService.getConfig()
      .pipe(
        tap((config) => {
          if (!config.firstLoad) {
            this.configFacadeService.getConfigAction();
          }
        }),
        filter(({firstLoad}) => firstLoad),
        first()
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.getFromStoreOrAPI()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
  }
}
