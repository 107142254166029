import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  Recommendation,
  DO_NOTHING_RECOMMENDATION_TYPE,
  NO_ACTION_DESCRIPTION,
  REDUCE_PRICE_CP_RECOMMENDATION_TYPE,
  CARRY_TO_NEXT_SEASON_DESCRIPTION,
} from '../../../../../../../../../api/abstracts/insights';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { formatPercent, formatCurrency, getCurrencySymbol } from '@angular/common';
import { currencyFormatter, currencyFormatterNoCents } from 'projects/sbx-ui-planning/src/app/infra/datagrid/datagrid.formatters';
import { first, map, filter } from 'rxjs/operators';
import { UserFacadeService } from 'sbx-ui-core';
import { RecommendationOptionsGridActionCellRendererComponent } from '../recommendation-options-grid-action-cell-renderer/recommendation-options-grid-action-cell-renderer.component';
import { RecommendationOptionsGridGraphCellRendererComponent } from '../recommendation-options-grid-graph-cell-renderer/recommendation-options-grid-graph-cell-renderer.component';

@Component({
  selector: 'app-recommendation-options-grid',
  templateUrl: './recommendation-options-grid.component.html',
  styleUrls: ['./recommendation-options-grid.component.scss'],
})
export class RecommendationOptionsGridComponent implements OnInit {
  @Input()
  recommendationOptions: Recommendation[];

  @Input()
  graphView;

  @Output()
  showGraphView = new EventEmitter();

  columnDefs: ColDef[];
  api: GridApi;

  gridOptions: GridOptions = {
    onGridReady: ({ api }) => {
      this.api = api;
      api.sizeColumnsToFit();
    },
    rowHeight: 48,
    headerHeight: 62,
    getRowClass: params => {
      if (params.data.recommended) {
        return 'recommended';
      }
    },
    domLayout: 'autoHeight',
    context: {
      parentComponent: this,
    },
  };

  constructor(private userFacadeService: UserFacadeService) {}

  ngOnInit() {
    this.userFacadeService
      .getUserCurrency()
      .pipe(
        filter(currency => !!currency),
        first()
      )
      .subscribe(currency => {
        const userCurrencySymbol = getCurrencySymbol(currency, 'narrow');
        this.columnDefs = [
          {
            headerName: '',
            field: 'action',
            suppressSizeToFit: false,
            minWidth: 220,
            maxWidth: 220,
            width: 220,
            valueFormatter: params => {
              const { current_price: currentPrice, action_price: actionPrice } = params.data;
              if (params.data.recommendation_type === DO_NOTHING_RECOMMENDATION_TYPE) {
                return CARRY_TO_NEXT_SEASON_DESCRIPTION;
              }
              if (params.data.recommendation_type === REDUCE_PRICE_CP_RECOMMENDATION_TYPE) {
                return NO_ACTION_DESCRIPTION;
              }

              if (actionPrice < currentPrice) {
                return `Reduce price to ${currencyFormatter(userCurrencySymbol)({ value: actionPrice })}`;
              } else {
                return `Increase price to ${currencyFormatter(userCurrencySymbol)({ value: actionPrice })}`;
              }
            },
          },
          {
            headerName: 'MD Price Required',
            field: 'mdPrice',
            valueFormatter: ({ value, data }) =>
              `${currencyFormatter(userCurrencySymbol)({ value })} (${formatPercent(data.mdDiscountRequired, 'en-US', '1.0-0')})`,
          },
          { headerName: 'Terminal Stock', field: 'terminalStock', valueFormatter: ({ value }) => `${value} u` },
          {
            headerName: 'Disposal Cost',
            field: 'disposalCost',
            valueFormatter: ({ value, data }) => {
              if (data.recommendation_type === DO_NOTHING_RECOMMENDATION_TYPE) {
                return CARRY_TO_NEXT_SEASON_DESCRIPTION;
              }

              return currencyFormatterNoCents(userCurrencySymbol)({ value });
            },
          },
          {
            headerName: 'Cash Margin on Remaining Sales',
            field: 'cashMarginOnRemainingSales',
            valueFormatter: ({ value }) => currencyFormatterNoCents(userCurrencySymbol)({ value }),
          },
          {
            headerName: 'Net Margin',
            field: 'netMargin',
            valueGetter: params => {
              if (params.data) {
                return params.data.cashMarginOnRemainingSales - params.data.disposalCost;
              }
            },
            valueFormatter: ({ value }) => currencyFormatterNoCents(userCurrencySymbol)({ value }),
          },
          {
            headerName: 'Action',
            suppressSizeToFit: false,
            width: 100,
            cellRendererFramework: RecommendationOptionsGridActionCellRendererComponent,
          },
          {
            headerName: '',
            suppressSizeToFit: false,
            width: 100,
            cellRendererFramework: RecommendationOptionsGridGraphCellRendererComponent,
          },
        ];
      });
  }

  switchToGraphView(params) {
    this.graphView = true;
    this.showGraphView.emit(params);
  }

  showGridView() {
    this.graphView = false;
    this.api.setRowData(this.recommendationOptions);
    this.showGraphView.emit(null);
  }

}
