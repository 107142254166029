import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Recommendation,
  RecommendationAction,
  Forecast,
  ProductHistory
} from '../../../../../../../../api/abstracts/insights/recommendation.model';

@Injectable({
  providedIn: 'root',
})
export class RecommendationService {
  constructor(private http: HttpClient) {}

  loadAll(): Observable<Recommendation[]> {
    return this.http.get<Recommendation[]>('/api/insight/recommendation').pipe(
      map(recs =>
        recs.map(rec => {
          if (rec.actions) {
            rec.actions.forEach(({ action }) => {
              rec[action] = true;
            });
          }

          return rec;
        })
      )
    );
  }

  like(id: number): Observable<RecommendationAction> {
    return this.http.post<RecommendationAction>('/api/insight/action', { recommendation_id: id, action: 'like' });
  }

  take(id: number): Observable<RecommendationAction> {
    return this.http.post<RecommendationAction>('/api/insight/action', { recommendation_id: id, action: 'take' });
  }

  bin(id: number): Observable<RecommendationAction> {
    return this.http.post<RecommendationAction>('/api/insight/action', { recommendation_id: id, action: 'bin' });
  }

  removeAction(actionID: number): Observable<any> {
    return this.http.delete(`/api/insight/action/${actionID}`);
  }

  loadForecast(id: number): Observable<Forecast[]> {
    return this.http.get<Forecast[]>(`/api/insight/recommendation/${id}/forecast`);
  }

  loadProductHistory(id: number): Observable<ProductHistory[]> {
    return this.http.get<ProductHistory[]>(`/api/insight/recommendation/${id}/product-history`);
  }
}
