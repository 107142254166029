import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { InsightPredictionConfig } from '../../../../../../../../api/abstracts/insights/config.model';
import { GetConfigSuccess,  UpdateConfigSuccess } from './config.actions';

export interface ConfigState extends EntityState<InsightPredictionConfig> { }

export const configAdapter: EntityAdapter<InsightPredictionConfig> = createEntityAdapter<InsightPredictionConfig>();

export const initialState: InsightPredictionConfig = {
    regions: [],
    regionTargetDates: {},
    preEventWeeks: null,
    priceEvent2Weeks: null,
    priceEvent1Type: null,
    optimisationTarget: null,
    optimisationMethod: null,
    minimumDiscount: null,
    maximumDiscount: null,
    minPriceChangeDiscount: null,
    minimumMargin: null,
    priceScenarios: [],
    nsalesMin: null,
    sellthroughMax: null,
    firstSeason: [],
    lastSeason: [],
    inventoryMin: null,
    weeksSinceLastPriceChange: null,
    weeksCover: null,
    minimumUnitImprovement: null,
    minimumUnitPercentImprovement: null,
    minimumMarginImprovement: null,
    minimumMarginPercentImprovement: null,
};

const configReducer = createReducer(
    initialState,
    on(GetConfigSuccess, UpdateConfigSuccess, (state, {config}) => {
        return {
            ...state,
            regions: config.regions,
            regionTargetDates: config.regionTargetDates,
            preEventWeeks: config.preEventWeeks,
            priceEvent2Weeks: config.priceEvent2Weeks,
            priceEvent1Type: config.priceEvent1Type,
            optimisationTarget: config.optimisationTarget,
            optimisationMethod: config.optimisationMethod,
            minimumDiscount: config.minimumDiscount,
            maximumDiscount: config.maximumDiscount,
            minPriceChangeDiscount: config.minPriceChangeDiscount,
            minimumMargin: config.minimumMargin,
            priceScenarios: config.priceScenarios,
            nsalesMin: config.nsalesMin,
            sellthroughMax: config.sellthroughMax,
            firstSeason: config.firstSeason,
            lastSeason: config.lastSeason,
            inventoryMin: config.inventoryMin,
            weeksSinceLastPriceChange: config.weeksSinceLastPriceChange,
            weeksCover: config.weeksCover,
            minimumUnitImprovement: config.minimumUnitImprovement,
            minimumUnitPercentImprovement: config.minimumUnitPercentImprovement,
            minimumMarginImprovement: config.minimumMarginImprovement,
            minimumMarginPercentImprovement: config.minimumMarginPercentImprovement,
            firstLoad: true
          };
    })
);

export function reducer(state: InsightPredictionConfig | undefined, action: Action) {
    return configReducer(state, action);
}