import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RecommendationFacadeService } from '../../services';
import * as _ from 'lodash';

@Component({
  selector: 'app-recommendation-options-grid-action-cell-renderer',
  templateUrl: './recommendation-options-grid-action-cell-renderer.component.html',
  styleUrls: ['./recommendation-options-grid-action-cell-renderer.component.scss'],
})
export class RecommendationOptionsGridActionCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params: ICellRendererParams;
  constructor(private recommendationFacadeService: RecommendationFacadeService) {}

  agInit(params) {
    this.params = params;
  }

  refresh(params) {
    return true;
  }

  ngOnInit() {}

  onChange({ target }) {
    const { checked } = target;
    if (checked) {
      this.recommendationFacadeService.takeRecommendation(this.params.data.id);
    } else {
      const { id } = _.find(this.params.data.actions, ({ action }) => action === 'take');
      this.recommendationFacadeService.untakeRecommendation(id, this.params.data.id);
    }
  }
}
