import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { CookieService } from 'ngx-cookie-service';
import { TagInputModule } from 'ngx-chips';
import { AgGridModule } from 'ag-grid-angular';
import { Ng5SliderModule } from 'ng5-slider';
import { MatExpansionModule, MatIconModule } from '@angular/material';
import { SbxUiCoreModule, AuthInterceptor } from 'sbx-ui-core';
import { SidebarModule } from 'ng-sidebar';

import { AppComponent } from './app.component';
import { reducers } from './app.reducer';
import { AppRoutingModule } from './app-routing.module';
import { RecommendationComponent, RecommendationEffects } from './features/recommendation';
import { RecommendationDetailComponent } from './features/recommendation/components/recommendation-detail/recommendation-detail.component';
import { RecommendationOverviewComponent } from './features/recommendation/components/recommendation-overview/recommendation-overview.component';
import { RecommendationGridComponent } from './features/recommendation/components/recommendation-grid/recommendation-grid.component';
import { NavigationComponent } from './infra/navigation/navigation.component';
import { RecommendationListComponent } from './features/recommendation/components/recommendation-list/recommendation-list.component';
import { RecommendationImageComponent } from './infra/recommendation-image/recommendation-image.component';
import { SettingsComponent } from './features/settings/settings.component';
import { MatSelectModule } from '@angular/material';
import { RecommendationOptionsGridComponent } from './features/recommendation/components/recommendation-options-grid/recommendation-options-grid.component';
import { RecommendationOptionsGridActionCellRendererComponent } from './features/recommendation/components/recommendation-options-grid-action-cell-renderer/recommendation-options-grid-action-cell-renderer.component';
import { SliderComponent } from './infra/slider/slider.component';
import { DisposalCostGridComponent } from './features/settings/disposal-cost-grid/disposal-cost-grid.component';
import { RegionTargetDatesGridComponent } from './features/settings/region-target-dates-grid/region-target-dates-grid.component';
import { DisposalCostCellEditorComponent } from './features/settings/disposal-cost-grid/disposal-cost-cell-editor/disposal-cost-cell-editor.component';
import { TargetDatesCellEditorComponent } from './features/settings/region-target-dates-grid/target-dates-cell-editor/target-dates-cell-editor.component';
import { ConfigEffects } from './features/settings/state/config.effects';
import { TargetDatesGridActionsComponent } from './features/settings/region-target-dates-grid/target-dates-grid-actions/target-dates-grid-actions.component';
import { RecommendationSummaryGridComponent } from './features/recommendation/components/recommendation-summary-grid/recommendation-summary-grid.component';
import { DecimalPipe } from '@angular/common';
import { RecommendationListFilterSidebarComponent } from './features/recommendation/components/recommendation-list-filter-sidebar/recommendation-list-filter-sidebar.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { RecommendationOptionsGraphComponent } from './features/recommendation/components/recommendation-options-graph/recommendation-options-graph.component';
import { RecommendationOptionsGridGraphCellRendererComponent } from './features/recommendation/components/recommendation-options-grid-graph-cell-renderer/recommendation-options-grid-graph-cell-renderer.component';

@NgModule({
  declarations: [
    AppComponent,
    RecommendationComponent,
    RecommendationDetailComponent,
    RecommendationOverviewComponent,
    RecommendationGridComponent,
    NavigationComponent,
    RecommendationListComponent,
    RecommendationImageComponent,
    SettingsComponent,
    RecommendationOptionsGridComponent,
    RecommendationOptionsGridActionCellRendererComponent,
    SliderComponent,
    DisposalCostGridComponent,
    RegionTargetDatesGridComponent,
    DisposalCostCellEditorComponent,
    TargetDatesCellEditorComponent,
    TargetDatesGridActionsComponent,
    RecommendationSummaryGridComponent,
    RecommendationListFilterSidebarComponent,
    RecommendationOptionsGraphComponent,
    RecommendationOptionsGridGraphCellRendererComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([RecommendationEffects, ConfigEffects]),
    SbxUiCoreModule,
    AppRoutingModule,
    TagInputModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    AgGridModule.withComponents([
      RecommendationOptionsGridActionCellRendererComponent,
      DisposalCostCellEditorComponent,
      TargetDatesCellEditorComponent,
      TargetDatesGridActionsComponent,
      RecommendationOptionsGridGraphCellRendererComponent
    ]),
    Ng5SliderModule,
    MatExpansionModule,
    MatIconModule,
    SidebarModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ChartsModule
  ],
  providers: [
    CookieService,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ThemeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
