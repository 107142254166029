import { createAction, props } from '@ngrx/store';

import { Recommendation, RecommendationAction, Forecast, ProductHistory } from '../../../../../../../../api/abstracts/insights';

export enum RecommendationActionTypes {
  LoadAll = '[Recommendation] Load All',
  LoadAllSuccess = '[Recommendation] Load All Success',
  LoadAllFailure = '[Recommendation] Load All Failure',
  Like = '[Recommendation] Like',
  LikeSuccess = '[Recommendation] Like Success',
  LikeFailure = '[Recommendation] Like Failure',
  Unlike = '[Recommendation] Unlike',
  UnlikeSuccess = '[Recommendation] Unlike Success',
  UnlikeFailure = '[Recommendation] Unlike Failure',
  Take = '[Recommendation] Take',
  TakeSuccess = '[Recommendation] Take Success',
  TakeFailure = '[Recommendation] Take Failure',
  Untake = '[Recommendation] Untake',
  UntakeSuccess = '[Recommendation] Untake Success',
  UntakeFailure = '[Recommendation] Untake Failure',
  Bin = '[Recommendation] Bin',
  BinSuccess = '[Recommendation] Bin Success',
  BinFailure = '[Recommendation] Bin Failure',
  Unbin = '[Recommendation] Unbin',
  UnbinSuccess = '[Recommendation] Unbin Success',
  UnbinFailure = '[Recommendation] Unbin Failure',
  LoadForecast = '[Recommendation] Load Forecast',
  LoadForecastSuccess = '[Recommendation] Load Forecast Success',
  LoadForecastFailure = '[Recommendation] Load Forecast Failure',
  UpdateCarouselPage = '[Recommendation] Update Carousel Page',
  UpdateSortOption = '[Recommendation] Update Sort Option',
  UpdateListFilter = '[Recommendation] Update List Filter',
  LoadProductHistory = '[Recommendation] Load Product History',
  LoadProductHistorySuccess = '[Recommendation] Load Product History Success',
  LoadProductHistoryFailure = '[Recommendation] Load Product History Failure',
}

export const LoadAll = createAction(RecommendationActionTypes.LoadAll);

export const LoadAllSuccess = createAction(RecommendationActionTypes.LoadAllSuccess, props<{ entities: Recommendation[] }>());

export const LoadAllFailure = createAction(RecommendationActionTypes.LoadAllFailure, props<{ error: any }>());

export const Like = createAction(RecommendationActionTypes.Like, props<{ id: number }>());

export const LikeSuccess = createAction(RecommendationActionTypes.LikeSuccess, props<{ action: RecommendationAction }>());

export const LikeFailure = createAction(RecommendationActionTypes.LikeFailure, props<{ error: any }>());

export const Unlike = createAction(RecommendationActionTypes.Unlike, props<{ actionID: number; id: number }>());

export const UnlikeSuccess = createAction(RecommendationActionTypes.UnlikeSuccess, props<{ actionID: number; id: number }>());

export const UnlikeFailure = createAction(RecommendationActionTypes.UnlikeFailure, props<{ error: any }>());

export const Take = createAction(RecommendationActionTypes.Take, props<{ id: number }>());

export const TakeSuccess = createAction(RecommendationActionTypes.TakeSuccess, props<{ action: RecommendationAction }>());

export const TakeFailure = createAction(RecommendationActionTypes.TakeFailure, props<{ error: any }>());

export const Untake = createAction(RecommendationActionTypes.Untake, props<{ actionID: number; id: number }>());

export const UntakeSuccess = createAction(RecommendationActionTypes.UntakeSuccess, props<{ actionID: number; id: number }>());

export const UntakeFailure = createAction(RecommendationActionTypes.UntakeFailure, props<{ error: any }>());

export const Bin = createAction(RecommendationActionTypes.Bin, props<{ id: number }>());

export const BinSuccess = createAction(RecommendationActionTypes.BinSuccess, props<{ action: RecommendationAction }>());

export const BinFailure = createAction(RecommendationActionTypes.BinFailure, props<{ error: any }>());

export const Unbin = createAction(RecommendationActionTypes.Unbin, props<{ actionID: number; id: number }>());

export const UnbinSuccess = createAction(RecommendationActionTypes.UnbinSuccess, props<{ actionID: number; id: number }>());

export const UnbinFailure = createAction(RecommendationActionTypes.UnbinFailure, props<{ error: any }>());

export const LoadForecast = createAction(RecommendationActionTypes.LoadForecast, props<{ id: number }>());

export const LoadForecastSuccess = createAction(
  RecommendationActionTypes.LoadForecastSuccess,
  props<{ entities: Forecast[]; id: number }>()
);

export const LoadForecastFailure = createAction(RecommendationActionTypes.LoadForecastFailure, props<{ error: any }>());

export const UpdateCarouselPage = createAction(RecommendationActionTypes.UpdateCarouselPage, props<{carouselPageIndex: number}>());

export const UpdateSortOption = createAction(RecommendationActionTypes.UpdateSortOption, props<{sortOption: string}>());

export const UpdateListFilter = createAction(RecommendationActionTypes.UpdateListFilter, props<{formValues}>());

export const LoadProductHistory = createAction(
  RecommendationActionTypes.LoadProductHistory,
   props<{ id: number, regionRecsIds: number[]}>()
);

export const LoadProductHistorySuccess = createAction(
  RecommendationActionTypes.LoadProductHistorySuccess,
  props<{ entities: ProductHistory[]; id: number, regionRecsIds: number[] }>()
);

export const LoadProductHistoryFailure = createAction(RecommendationActionTypes.LoadProductHistoryFailure, props<{ error: any }>());
