import { Component, OnInit, Input } from '@angular/core';

import * as _ from 'lodash';

import { UserFacadeService } from 'sbx-ui-core';
import {
  Recommendation,
  ACTION_LIKE,
  ACTION_TAKE,
  ACTION_BIN,
  MARGIN_SORT,
  VOLUME_SORT
 } from '../../../../../../../../../api/abstracts/insights';

import { RecommendationFacadeService } from '../../services';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss'],
})
export class RecommendationComponent implements OnInit {
  @Input()
  recommendation: Recommendation;

  @Input()
  sortOptionChanged$: BehaviorSubject<string>;

  userCurrency$: Observable<string>;

  recToClass = {
    pricedown: 'reduce-price',
    pricedownCP: 'reduce-price',
    pricedownP: 'reduce-price',
    'increase price': 'increase-price',
    'reduce price': 'reduce-price',
    'remove discount': 'remove-discount',
  };

  recToString = {
    pricedown: '- $ reduce price',
    pricedownCP: '- $ reduce price',
    pricedownP: '- $ reduce price',
    'reduce price': '- $ reduce price',
    'remove discount': 'remove discount',
    'increase price': '+ $ increase price',
  };

  private binTimeout: number;
  sortOption = MARGIN_SORT;

  // todo: this component should be used everywhere
  constructor(private recommendationFacadeService: RecommendationFacadeService, private userFacadeService: UserFacadeService) {}

  ngOnInit() {
    this.userCurrency$ = this.userFacadeService.getUserCurrency();
    this.userCurrency$
      .pipe(
        filter(currency => !!currency),
        first()
      )
      .subscribe(currency => {
        const userCurrencySymbol = getCurrencySymbol(currency, 'narrow');
        _.keys(this.recToString).forEach(key => {
          this.recToString[key] = this.recToString[key].replace('$', userCurrencySymbol);
        });
      });
  }

  undoBin(event: MouseEvent) {
    event.stopPropagation();
    this.recommendation.bin = false;
    clearTimeout(this.binTimeout);
  }

  onClickLike(event: MouseEvent) {
    event.stopPropagation();
    const { id, like } = this.recommendation;
    if (like) {
      const { id: actionID } = _.find(this.recommendation.actions, ({ action }) => action === ACTION_LIKE);

      this.recommendationFacadeService.unlikeRecommendation(actionID, id);
    } else {
      this.recommendationFacadeService.likeRecommendation(id);
    }
  }

  onClickTake(event: MouseEvent) {
    event.stopPropagation();
    const { id, take } = this.recommendation;
    if (take) {
      const { id: actionID } = _.find(this.recommendation.actions, ({ action }) => action === ACTION_TAKE);

      this.recommendationFacadeService.untakeRecommendation(actionID, id);
    } else {
      this.recommendationFacadeService.takeRecommendation(id);
    }
  }

  onClickBin(event: MouseEvent) {
    event.stopPropagation();
    this.recommendation.bin = true;
    this.binTimeout = window.setTimeout(() => {
      const { id } = this.recommendation;
      // this checks for binRec because bin gets set by the onClick to cause the greyscale to appear
      const binRec = _.find(this.recommendation.actions, ({ action }) => action === ACTION_BIN);
      if (binRec) {
        const { id: actionID } = binRec;

        this.recommendationFacadeService.unbinRecommendation(actionID, id);
      } else {
        this.recommendationFacadeService.binRecommendation(id);
      }
    }, 3000);
  }
}
