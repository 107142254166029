import { Component } from '@angular/core';
import { RecommendationFacadeService } from './features/recommendation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private recommendationFacadeService: RecommendationFacadeService) {
    this.recommendationFacadeService.loadAll();
  }
}
