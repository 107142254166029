import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from '../../../app.reducer';
import * as fromRecommendationActions from '../state/recommendation.actions';
import { getCarouselPageIndex, getSortOption, getListFilters } from '../state/recommendation.selectors';

@Injectable({
  providedIn: 'root'
})
export class SharedUIFacadeService {

  constructor(private store: Store<fromRoot.State>) { }

  getCarouselPageIndex(): Observable<number> {
    return this.store.pipe(select(getCarouselPageIndex));
  }

  updateCarouselPageIndex(carouselPageIndex: number) {
    this.store.dispatch(fromRecommendationActions.UpdateCarouselPage({carouselPageIndex}));
  }

  getSortOption(): Observable<string> {
    return this.store.pipe(select(getSortOption));
  }

  updateSortOption(sortOption: string) {
    this.store.dispatch(fromRecommendationActions.UpdateSortOption({sortOption}));
  }

  getListFilters() {
    return this.store.pipe(select(getListFilters));
  }

  updateListFilters(formValues) {
    this.store.dispatch(fromRecommendationActions.UpdateListFilter({formValues}));
  }

}
