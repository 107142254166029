import { Component, Input, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { ColDef } from 'ag-grid-community';
import { TargetDatesCellEditorComponent } from './target-dates-cell-editor/target-dates-cell-editor.component';
import { TargetDatesGridActionsComponent } from './target-dates-grid-actions/target-dates-grid-actions.component';

@Component({
  selector: 'app-region-target-dates-grid',
  templateUrl: './region-target-dates-grid.component.html',
  styleUrls: ['./region-target-dates-grid.component.scss']
})
export class RegionTargetDatesGridComponent implements OnInit {
  @Input()
  regionTargetDates;

  @Input()
  gridOptions;

  columnDefs: ColDef[];
  rowData = [];
  constructor() { }

  ngOnInit() {
    _(this.regionTargetDates).forIn((value, key) => {
      this.rowData.push({
        region: key,
        targetDate: value
      });
    });
    this.columnDefs = [
      { headerName: 'Region', field: 'region', width: 240 },
      { headerName: 'Target Dates', field: 'targetDate', width: 160, cellRendererFramework: TargetDatesCellEditorComponent },
      { headerName: '', field: 'action', width: 70, cellRendererFramework: TargetDatesGridActionsComponent },
    ];
  }

}
