import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { RecommendationOptionsGridComponent } from '../recommendation-options-grid/recommendation-options-grid.component';

@Component({
  selector: 'app-recommendation-options-grid-graph-cell-renderer',
  templateUrl: './recommendation-options-grid-graph-cell-renderer.component.html',
  styleUrls: ['./recommendation-options-grid-graph-cell-renderer.component.scss']
})
export class RecommendationOptionsGridGraphCellRendererComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams;
  parentComponent: RecommendationOptionsGridComponent;
  constructor() {}

  agInit(params) {
    this.params = params;
    this.parentComponent = this.params.context && this.params.context.parentComponent;
  }

  refresh(params) {
    return true;
  }

  ngOnInit() {}

  showGraphView() {
    this.parentComponent.graphView = true;
    this.params.api.setRowData([this.params.data]);
    this.parentComponent.switchToGraphView(this.params.data);
  }

  switchToGrid() {
    this.parentComponent.showGridView();
  }

}
