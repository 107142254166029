import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ConfigFacadeService } from '../../services/config-facade.service';
import * as _ from 'lodash';
import { SettingsComponent } from '../../settings.component';

@Component({
  selector: 'app-target-dates-grid-actions',
  templateUrl: './target-dates-grid-actions.component.html',
  styleUrls: ['./target-dates-grid-actions.component.scss']
})
export class TargetDatesGridActionsComponent implements OnInit {

  @Input()
  regionTargetDates;

  params: any;
  parentComponent: SettingsComponent;

  agInit(params: any): void {
    this.params = params;
    this.parentComponent = this.params.context && this.params.context.parentComponent;
  }

  constructor() { }
  ngOnInit() {
  }

  refresh(): boolean {
    return false;
  }

  deleteRegion() {
    const regionsAfterRemove = _.omit(this.parentComponent.regionTargetDates, [this.params.data.region]);
    this.parentComponent.updateChanges('regionTargetDates', regionsAfterRemove);
    this.removeRegion();
  }

  removeRegion() {
    this.params.api.updateRowData({ remove: [this.params.data] });
  }

  saveRegionTargetDate() {
    if (this.params.data.new && this.params.data.targetDate ) {
      this.parentComponent.regionTargetDates[this.params.data.region] = this.params.data.targetDate;
    } else {
      this.parentComponent.regionTargetDates = _.update(this.parentComponent.regionTargetDates,
      this.params.data.region, (n) => this.params.data.targetDate);
    }
    this.parentComponent.updateChanges('regionTargetDates', this.parentComponent.regionTargetDates);
    this.params.data.new = false;
    this.params.data.changed = false;
  }

}
