import { createAction, props } from '@ngrx/store';
import { InsightPredictionConfig } from '../../../../../../../../api/abstracts/insights/config.model';

export enum ConfigActionTypes {
    GetConfig = '[Config] Get Config',
    GetConfigSuccess = '[Config] Get Config Success',
    GetConfigFailure = '[Config] Get Config Failure',
    UpdateConfig = '[Config] Update Config',
    UpdateConfigSuccess = '[Config] Update Config Success',
    UpdateConfigFailure = '[Config] Update Config Failure',
}

export const GetConfig = createAction(ConfigActionTypes.GetConfig);

export const GetConfigSuccess = createAction(ConfigActionTypes.GetConfigSuccess, props<{ config: InsightPredictionConfig }>());

export const GetConfigFailure = createAction(ConfigActionTypes.GetConfigFailure, props<{ error: any }>());

export const UpdateConfig = createAction(ConfigActionTypes.UpdateConfig, props<{ params: Partial<InsightPredictionConfig> }>());

export const UpdateConfigSuccess = createAction(ConfigActionTypes.UpdateConfigSuccess, props<{ config: InsightPredictionConfig }>());

export const UpdateConfigFailure = createAction(ConfigActionTypes.UpdateConfigFailure, props<{ error: any }>());
